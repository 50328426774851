import React, { Component } from 'react';
import Footer from '../../components/Footer';
import './Login.css';
import '../common.css';
import LoginHeader from '../../components/LoginHeader';
import Header from '../../components/Header';
import {jwtDecode} from 'jwt-decode';
var moment = require('moment');
require('dotenv').config();
const bcrypt = require('bcryptjs');
const salt = "$2a$12$nskGg4yxHrplv8cio.P24.";

let security = sessionStorage.getItem("security");
var isLoggedIn = sessionStorage.getItem("isLoggedIn");
const database = process.env.REACT_APP_DATABASE?process.env.REACT_APP_DATABASE:"https://activtracecare.com/data";
sessionStorage.setItem("database", database);
console.log("DATABASE:",database);


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginErrors: false,
            loginError: "",
            errorMsg: "",
            loginAuthUser: null,
            loginAuthPassword: null,
        };
        this.validateLogin = this.validateLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //this.getRoles();
    }

    getRoles = (token) => {
        fetch(`${database}/users/roles/?token=${token}`)
        .then(response => response.json())
        .then(roles => this.setState({ roles: roles.data }))
        // .then(console.log("Roles:", JSON.stringify(this.state.roles)))
        .catch(err => console.error(err));        
    }

    validateLogin() {
        console.log(process.env)
        this.setState({
            // isLoggedIn: false,
            loginErrors: false,
            loginError: "",
            errorMsg: "",
        });
        // from login button
        if (!this.state.loginAuthUser) {
            isLoggedIn = false;
            this.setState({
                // isLoggedIn: false,
                loginErrors: true,
                loginError: "USERNAME IS MISSING",
                errorMsg: "Please enter a username.",
            });
        } 
        else if (!this.state.loginAuthPassword) {
            isLoggedIn = false;
            this.setState({
                // isLoggedIn: false,
                loginErrors: true,
                loginError: "PASSWORD IS MISSING",
                errorMsg: "Please enter a password.",
            });
        } 
        else {

            let hash = bcrypt.hashSync(this.state.loginAuthPassword, salt);

            console.log("User", database)
            console.log(`${database}/login/?username=${this.state.loginAuthUser}&password=${hash}`)
            fetch(`${database}/login/?username=${this.state.loginAuthUser}&password=${hash}`)
            .then(response => response.json())
	        .then((payload) => {
                const datetimeNow = new Date();
                // console.log("Datetime NOW::", datetimeNow.getTime());
                const token = payload.token;
                const refresh_token = payload.refresh_token;
                this.getRoles(token);
                //console.log(JSON.stringify(jwtDecode(token)));
                var user = jwtDecode(token);
                isLoggedIn = true;
                console.log("LOGGED IN")
                this.setState({
                    // isLoggedIn: true, 
                    loginErrors: false,
                    loginError: "",
                    errorMsg: "",
                });
                //const roles = this.state.roles;
                const role_id = user.role;
                const user_id = user.user_id;
                const security = user.security;
                
                //let role = roles.find((role) => {return role.id === role_id});
                //console.log("ROLE::", role);
                sessionStorage.setItem("isLoggedIn", "true");
                sessionStorage.setItem("token",token);
                sessionStorage.setItem("refresh_token",refresh_token);
                sessionStorage.setItem("access_time",datetimeNow.getTime());
                //this.security = role.security;
                sessionStorage.setItem("security", security);
                sessionStorage.setItem("user_id", user_id);
                window.location.href="/contact-report";
            })
            .catch((err) => {
                console.error("Error retrieving user:", err);
                isLoggedIn = false;
                this.setState({
                    // isLoggedIn: false,
                    loginErrors: true,
                    loginError: "ERROR LOGGING IN",
                    errorMsg: "Please re-enter username and password",
                    });
                return;
            });  
        }

    }
    
    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    render() {
        return (
            <div id="login-wrapper">
                <LoginHeader/>
                <div id='title-container'>
                    <div className="page-title">Login</div>
                </div>
                <div className={this.state.loginErrors?"gradient-container red-gradient":"gradient-container blue-gradient"}>
                
                    <div id="login-controls">
                        <div className="login-message">
                            <div className="login-error">{this.state.loginError}</div>
                            <div className="error-message">{this.state.errorMsg}</div>
                        </div>
   
                        <div className="login-input-group">
                            <div className="login-input-label">Username</div>
                            <input 
                                id="loginAuthUser"
                                type="text"
                                name="username"
                                value={this.state.loginAuthUser}
                                onChange={this.handleChange}
                                placeholder="Enter Username"
                            />
                        
                            <div className="login-input-label">Password</div>
                            <input 
                                id="loginAuthPassword"
                                type="password"
                                name="password"
                                value={this.state.loginAuthPassword}
                                onChange={this.handleChange}
                                placeholder="Enter Password"                            
                            />
                        </div>

                        <div />

                        <button 
                            id="login-button" 
                            onClick={this.validateLogin}>
                            Login
                        </button>

                        <button 
                            id="cancel-login-button" 
                            onClick={()=>{window.location.href="/landing"}}
                        >
                            Cancel
                        </button>
                        
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Login;
