import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  CssBaseline,
  IconButton,
  Tooltip,
  Box,
  Paper,
  AppBar,
  Toolbar,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import {PDFObject} from 'react-pdfobject';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';


const token = sessionStorage.getItem("token");
const database = sessionStorage.getItem("database");
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#27AAE1',
    },
    secondary: {
      main: '#39B54A',
    },
    logout: {
      main: '#BCBEC0'
    },
  },
});

const diseaseParameters = {
  'COVID-19': {
    imperial: {
      distance: 6,
      distanceUnit: 'feet',
    },
    metric: {
      distance: 2,
      distanceUnit: 'meters',
    },
    duration: 15,
    durationUnit: 'minutes',
    degree: 1,
  },
  'Flu': {
    imperial: {
      distance: 3,
      distanceUnit: 'feet',
    },
    metric: {
      distance: 1,
      distanceUnit: 'meters',
    },
    duration: 10,
    durationUnit: 'minutes',
    degree: 1,
  },
  'Measles': {
    imperial: {
      distance: 10,
      distanceUnit: 'feet',
    },
    metric: {
      distance: 3,
      distanceUnit: 'meters',
    },
    duration: 30,
    durationUnit: 'minutes',
    degree: 1,
  },
  'Custom': {
    imperial: {
      distance: 0,
      distanceUnit: 'feet',
    },
    metric: {
      distance: 0,
      distanceUnit: 'meters',
    },
    duration: 0,
    durationUnit: 'minutes',
    degree: 1,
  }
};

const ReportForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    disease: 'COVID-19',
    distance: 6,
    distanceUnit: 'feet',
    duration: 15,
    durationUnit: 'minutes',
    target: '',
    'date-from': dayjs('2010-01-01'),
    'date-to': dayjs(),
    degree: '1',
    incubationHours: '0',
    incubationMinutes: '0',
  });
  const [selectedDisease, setSelectedDisease] = useState('COVID-19');
  const [selectedDistance, setSelectedDistance] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [globalUnits, setGlobalUnits] = useState('imperial');
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const theme = useTheme();
  const formRef = useRef(null);

  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem('formData') || '{}');
    const savedUnits = localStorage.getItem('units') || 'imperial';
    
    setFormData(prevData => ({
      ...prevData,
      ...savedFormData,
      'date-from': savedFormData['date-from'] ? dayjs(savedFormData['date-from']) : dayjs().subtract(10, 'day'),
      'date-to': savedFormData['date-to'] ? dayjs(savedFormData['date-to']) : dayjs(),
    }));
    setGlobalUnits(savedUnits);
    
    if (savedFormData.disease) {
      setSelectedDisease(savedFormData.disease);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    setFormData((prevFormData) => {
      const unitSystem = globalUnits === 'imperial' ? 'imperial' : 'metric';
      return {
        ...prevFormData,
        distanceUnit: prevFormData.distanceUnit || diseaseParameters[selectedDisease][unitSystem]?.distanceUnit,
        distance: prevFormData.distance || diseaseParameters[selectedDisease][unitSystem]?.distance,
      };
    });
  }, [globalUnits, selectedDisease]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'disease') {
      setSelectedDisease(value);
    }

    //check if parameters are changed when specific disease selected so selectedDisease is changed to 'Custom' 
    if (name === 'distance' || name === 'duration' || name === 'degree') {
      const currentDisease = diseaseParameters[selectedDisease];
      
      if (selectedDisease !== 'Custom' && currentDisease) {
        const unitSystem = globalUnits === 'imperial' ? 'imperial' : 'metric';
        
        if (
          (name === 'distance' && currentDisease[unitSystem] && value !== currentDisease[unitSystem].distance) ||
          (name === 'duration' && value !== currentDisease.duration) ||
          (name === 'degree' && value !== currentDisease.degree)
        ) {
          setSelectedDisease('Custom');
        }
      }

      if(name === 'distance'){
        setFormData(prevState => ({
          ...prevState,
          distance: Number(event.target.value)
        }) )
      }

      if(name === 'duration'){
        setFormData(prevState => ({
          ...prevState,
          duration: Number(event.target.value)
        }) )
      }
    }
  };

  const handleDateTimeChange = (newValue, fieldName) => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: newValue
    }));
  };

  const handleDiseaseChange = (event) => {
    const disease = event.target.value;
    setSelectedDisease(disease);
      const unitSystem = globalUnits === 'imperial' ? 'imperial' : 'metric';
      setFormData(prevData => ({
        ...prevData,
        disease,
        ...diseaseParameters[disease][unitSystem],
        duration: diseaseParameters[disease].duration,
        durationUnit: diseaseParameters[disease].durationUnit,
        degree: diseaseParameters[disease].degree,
      }));
  };

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setFormData({
      'date-from': dayjs(),
      'date-to': dayjs(),
    });
    setSelectedDisease('COVID-19');
    localStorage.removeItem('formData');
  };

  const validateForm = () => {
    let tempErrors = {};
    
    if (!formData.distance || formData.distance <= 0) {
      tempErrors.distance = "Distance must be a positive number";
    }
    
    if (!formData.duration || formData.duration <= 0) {
      tempErrors.duration = "Duration must be a positive number";
    }
    
    if (!formData.target) {
      tempErrors.target = "Caregiver/Patient ID is required";
    }
    if (formData.target <= 0) {
      tempErrors.target = "Caregiver/Patient ID can't be less than or equal to 0";
    }
    if (!Number.isInteger(Number(formData.target))) {
      tempErrors.target = "Caregiver/Patient ID must be a whole number";
    }
    if (!formData['date-from']) {
      tempErrors['date-from'] = "From date and time is required";
    }
    
    if (!formData['date-to']) {
      tempErrors['date-to'] = "To date and time is required";
    }
    
    if (formData['date-from'] && formData['date-to'] && formData['date-from'].isAfter(formData['date-to'])) {
      tempErrors['date-range'] = "From date must be before To date";
    }
    
    // if (!formData.degree || (formData.degree !== 1 && formData.degree !== 2)) {
    //   tempErrors.degree = "Degree must be 1 or 2";
    // }
    
    if (formData.degree === 2) {
      if (!formData.incubationHours && !formData.incubationMinutes) {
        tempErrors.incubation = "Incubation period is required for 2nd degree contacts";
      }
    }
    console.log(tempErrors);
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    if (!validateForm()) {
      console.error('Form validation failed');
      setIsSubmitting(false);
      return;
    }

    const convertedData = { ...formData };
    const diseaseName = selectedDisease;
    localStorage.setItem("diseaseName", diseaseName);

    switch (formData.distanceUnit) {
      case 'feet':
        convertedData.distance = formData.distance * 0.3048;
        break;
      case 'yards':
        convertedData.distance = formData.distance * 0.9144;
        break;
      case 'kilometers':
        convertedData.distance = formData.distance * 1000;
        break;
      default:
        convertedData.distance = formData.distance;
    }

    switch (formData.durationUnit) {
      case 'minutes':
        convertedData.duration = formData.duration * 60;
        break;
      default:
        convertedData.duration = formData.duration;
    }

    let incubationHours = formData.incubationHours ? String(formData.incubationHours).padStart(2, '0') : '00';
    let incubationMinutes = formData.incubationMinutes ? String(formData.incubationMinutes).padStart(2, '0') : '00';

    let incubationTime = `${incubationHours}:${incubationMinutes}`;
    convertedData.incubationTime = incubationTime;

    convertedData['date-from'] = formData['date-from'].toDate();
    convertedData['date-to'] = formData['date-to'].toDate();


    localStorage.setItem('formData', JSON.stringify(formData));
    localStorage.setItem('convertedFormData', JSON.stringify(convertedData));
    localStorage.setItem('data', JSON.stringify(convertedData));
    console.log('Submitted Form Data: ', formData);
    // try {
    //   const postResponse = await fetch(`${database}/receive-data?token=${token}`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(convertedData),
    //   });

    //   if (postResponse.ok) {
    //     const result = await postResponse.json();
    //     console.log('Data received:', result.id);
    //     navigate('results');
    //   } else {
    //     console.error('Failed to submit data');
    //   }
    // } catch (error) {
    //   console.error('Error submitting form:', error);
    // } finally {
    //   setIsSubmitting(false);
    // }
    navigate('results');
  };

  const handleHelpOpen = () => 
    { 
      setHelpModalOpen(true); 
    };
  const handleHelpClose = () => { setHelpModalOpen(false); };

  const InfoTooltip = ({ title }) => (
    <Tooltip title={title}>
      <IconButton size="small">
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );

  const distanceUnits = globalUnits === 'imperial' ? ['feet', 'yards'] : ['meters', 'kilometers'];
  const durationUnits = ['seconds', 'minutes'];

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Container className= 'report-container' maxWidth="md" sx={{ mt: 4, mb: 40, height: 'calc(100vh - 128px)', zIndex: 'auto' }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ 
            fontWeight: 'bold', 
            textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
            mb: 3
          }}>
            New Contact Trace Form
          </Typography>
          <form ref={formRef} onSubmit={submitForm}>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h5" gutterBottom>
                Contact Parameters
              </Typography>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="disease-select">Select Disease</InputLabel>
                    <Select
                      value={selectedDisease}
                      onChange={handleDiseaseChange}
                      label="Select Disease"
                      inputProps={{
                        name: 'disease',
                        id: 'disease-select',
                      }}>
                      {Object.keys(diseaseParameters).map((disease) => (
                        <MenuItem key={disease} value={disease}>
                          {disease}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    {selectedDisease !== 'Custom' ? (
                      <>
                        Selecting a disease will set recommended contact parameters:
                        <br />
                        <strong>Distance:</strong> {diseaseParameters[selectedDisease][globalUnits === 'imperial' ? 'imperial' : 'metric'].distance} {diseaseParameters[selectedDisease][globalUnits === 'imperial' ? 'imperial' : 'metric'].distanceUnit}
                        <br />
                        <strong>Duration:</strong> {diseaseParameters[selectedDisease].duration} {diseaseParameters[selectedDisease].durationUnit}
                      </>
                    ) : (
                      "Custom disease selected. Please input your own parameters."
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <br />

              <Grid container spacing={2} marginBlockStart={0}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TextField
                        name="distance"
                        label="Distance"
                        type="number"
                        required
                        fullWidth
                        value={formData.distance}
                        onChange={handleChange}
                        error={!!errors.distance}
                        helperText={errors.distance || ""}
                        InputProps={{
                          endAdornment: <InfoTooltip title="Enter the maximum distance for considering close contact" />
                        }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          
                          <TextField
                           value={formData.distanceUnit || distanceUnits[0]}
                           onChange={handleChange}
                           name="distanceUnit"
                           label='Unit'
                           select
                         >
                           {distanceUnits.map((unit) => (
                             <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                           ))}
                         </TextField>
                       </FormControl>
                     </Grid>
                   </Grid>
                 </Grid>
                 <Grid item xs={12} sm={6}>
                   <Grid container spacing={1}>
                     <Grid item xs={8}>
                       <TextField
                         name="duration"
                         label="Duration"
                         type="number"
                         required
                         fullWidth
                         value={formData.duration}
                         onChange={handleChange}
                         error={!!errors.duration}
                         helperText={errors.duration || ""}
                         InputProps={{
                           endAdornment: <InfoTooltip title="Enter the minimum duration of contact for consideration" />
                         }}
                       />
                     </Grid>
                     <Grid item xs={4}>
                       <FormControl fullWidth>
                         <TextField
                           value={formData.durationUnit || durationUnits[0]}
                           onChange={handleChange}
                           name="durationUnit"
                           label='Unit'
                           select
                         >
                           {durationUnits.map((unit) => (
                             <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                           ))}
                         </TextField>
                       </FormControl>
                     </Grid>
                   </Grid>
                 </Grid>
               </Grid>
             </Paper>
   
             <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
               <Typography variant="h5" gutterBottom>
                 Contact Details
               </Typography>
               <TextField
                 name="target"
                 label="Caregiver/Patient ID"
                 type="number"
                 required
                 fullWidth
                 sx={{ mb: 2 }}
                 value={formData.target || ''}
                 onChange={handleChange}
                 error={!!errors.target}
                 helperText={errors.target || ""}
                 InputProps={{
                   endAdornment: <InfoTooltip title="Enter the unique identifier for the infected individual" />
                 }}
               />
               <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', p: 2, mb: 2 }}>
                 <Typography variant="h6" gutterBottom sx={{ fontSize: '1.1rem', marginBottom: '15px' }}>
                   Date and Time Range:
                 </Typography>
   
                 <Grid container spacing={2} marginBlockStart={-2}>
                   <Grid item xs={6}>
                     <DateTimePicker
                       label="From"
                       value={formData['date-from']}
                       onChange={(newValue) => handleDateTimeChange(newValue, 'date-from')}
                       renderInput={(params) => 
                         <TextField 
                           {...params} 
                           fullWidth 
                           required
                           error={!!errors['date-from']}
                           helperText={errors['date-from'] || ""}
                         />
                       }
                     />
                   </Grid>
                   <Grid item xs={6}>
                     <DateTimePicker
                       label="To"
                       value={formData['date-to']}
                       onChange={(newValue) => handleDateTimeChange(newValue, 'date-to')}
                       renderInput={(params) => 
                         <TextField 
                           {...params} 
                           fullWidth 
                           required
                           error={!!errors['date-to']}
                           helperText={errors['date-to'] || ""}
                         />
                       }
                     />
                   </Grid>
                 </Grid>
                 {errors['date-range'] && (
                   <Typography color="error" variant="caption">
                     {errors['date-range']}
                   </Typography>
                 )}
               </Box>
               <FormControl fullWidth>
                 
                 <TextField
                   name="degree"
                   label='Contact Degree'
                   select
                   value={formData.degree || ''}
                   onChange={handleChange}
                   required
                   fullWidth
                   error={!!errors.degree}
                 >
                   <MenuItem value={1}>1</MenuItem>
                   <MenuItem value={2}>2</MenuItem>
                 </TextField>
                 {errors.degree && (
                   <Typography color="error" variant="caption">
                     {errors.degree}
                   </Typography>
                 )}
               </FormControl>
     
               {formData.degree === 2 && (
                 <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', p: 2, mb: 2, mt:3 }}>
                   <Typography variant="h6" gutterBottom>
                     Incubation Period
                   </Typography>
                   <Grid container spacing={2}>
                     <Grid item xs={6}>
                       <TextField
                         name="incubationHours"
                         label="Hours"
                         type="number"
                         fullWidth
                         value={formData.incubationHours || 0}
                         onChange={handleChange}
                         error={!!errors.incubation}
                         helperText={errors.incubation || ""}
                         InputProps={{
                           inputProps: { min: 0 },
                           endAdornment: <InfoTooltip title="Enter the number of hours for the incubation period" />
                         }}
                       />
                     </Grid>
                     <Grid item xs={6}>
                       <TextField
                         name="incubationMinutes"
                         label="Minutes"
                         type="number"
                         fullWidth
                         value={formData.incubationMinutes || 0}
                         onChange={handleChange}
                         error={!!errors.incubation}
                         InputProps={{
                           inputProps: { min: 0, max: 59 },
                           endAdornment: <InfoTooltip title="Enter the additional minutes (0-59)" />
                         }}
                       />
                     </Grid>
                   </Grid>
                 </Box>
               )}
             </Paper>
           </form>
         </Container>
         <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 24, height: 50, marginTop: '10px', marginBottom: '10px' }}>
           <Toolbar sx={{ justifyContent: 'center', alignItems: 'center' }}>
             {/* <Box sx={{ position: 'absolute', left: 16 }}>
               <Tooltip title="Get help with filling out the form">
                 <IconButton color="inherit" onClick={handleHelpOpen}>
                   <HelpIcon />
                 </IconButton>
               </Tooltip>
             </Box> */}
             <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mb: '100' }}>
               <Button variant="contained" sx={{ bgcolor: '#EF4136', '&:hover': { bgcolor: '#D13026' } }} onClick={resetForm}>
                 CANCEL REPORT
               </Button>
               <Button variant="contained" sx={{ bgcolor: '#39B54A', '&:hover': { bgcolor: '#2A9D3A' } }} onClick={submitForm}>
                 GENERATE REPORT
               </Button>
             </Box>
             <Typography variant="caption" sx={{ position: 'absolute', right: 16, color: 'error.main' }}>
               * Denotes Required Field
             </Typography>
           </Toolbar>
         </AppBar>
         {/* <Modal
           open={helpModalOpen}
           onClose={handleHelpClose}
           aria-labelledby="help-modal-title"
           aria-describedby="help-modal-description"
         >
           <Box sx={{
             position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: '80%',
             height: '80%',
             bgcolor: 'background.paper',
             boxShadow: 24,
             p: 4,
             borderRadius: 2,
           }}>
             <IconButton
               sx={{
                 position: 'absolute',
                 right: 8,
                 top: 8,
                 color: (theme) => theme.palette.grey[500],
               }}
               onClick={handleHelpClose}
             >
               <CloseIcon />
             </IconButton>
             <Typography id="help-modal-title" variant="h6" component="h2" gutterBottom align="center">
               User Guide
             </Typography>
             <Box id = "user-guide-container" sx={{ width: '100%', height: 'calc(100% - 40px)', overflow: 'auto' }}>
               <PDFObject
                 url = "./UserGuide.pdf"
                 id="user-guide-pdf"
                 containerId = "user-guide-container"
                 height="100%" 
               />
             </Box>
           </Box>
         </Modal> */}
       </LocalizationProvider>
     </ThemeProvider>
   );
 };
 
 export default ReportForm;