import React, { Component, useState } from 'react';
import '../common.css';
import './Floors.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { callbackify } from 'util';
import { Tooltip } from 'antd';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import {styled} from '@mui/material/styles'
import FileUploadIcon from '@mui/icons-material/FileUpload';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

const site_id = sessionStorage.getItem("site_id");
const site_name = sessionStorage.getItem("site_name");
const database = sessionStorage.getItem("database");
const  HiddenFileInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
 });

// console.log("floor site id is:", site_id);

class Floors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // siteHeaderLabel: "Manage Floors for " + props.site.name,
            siteHeaderLabel1: "Manage Floors for "+site_name,
            siteHeaderLabel2: site_name,
            siteName: "",
            siteId: site_id,
            floors: [],
            floorName: "",
            floorLevel: "",
            floorPixelHeight: 0,
            floorPixelWidth: 0,
            floorRealHeight: "",
            validHeight: false,
            floorRealWidth: "",
            validWidth: false,
            swLongitude: "",
            validSWLon: false,
            swLatitude: "",
            validSWLat: false,
            nwLongitude: "",
            validNWLon: false,
            nwLatitude: "",
            validNWLat: false,
            floorplanData: "",
            floorplan: "",
            file_size: 0,
            mime_type: "",
            currentFloor: "",
            currentFloorName: "",
            adding_floor: false,
            editing_floor: false,
            edit_changes_made: false,
        };

        
        this.addFloor = this.addFloor.bind(this);
        this.editFloor = this.editFloor.bind(this);
        this.viewEditFloor = this.viewEditFloor.bind(this);
        this.viewAddFloor = this.viewAddFloor.bind(this);
        this.previewFile = this.previewFile.bind(this);
    }
    
    

    viewEditFloor(floor) {
        // edit is temporarily disabled
        // console.log("Floor:", floor);
        this.site_id = floor.site_id;
        let preview = "data:image/png;base64,"+floor.floorplan;
        this.setState({
            floorId: floor.id,
            editing_floor: true,
            edit_changes_made: false,
            floorName:floor.name,
            floorLevel:floor.level,
            siteId:floor.site_id,
            floorplan:floor.floorplan,
            floorplanData:floor.floorplan,
            preview: preview,
            mime_type:floor.mime_type,
            file_size:floor.file_size,
            floorPixelHeight: floor.pixel_height,
            floorPixelWidth: floor.pixel_width,
            floorRealHeight: floor.real_height,
            validHeight: true,
            floorRealWidth: floor.real_width,
            validWidth: true,
            swLongitude: floor.sw_longitude,
            validSWLon: true,
            swLatitude: floor.sw_latitude,
            validSWLat: true,
            nwLongitude: floor.nw_longitude,
            validNWLon: true,
            nwLatitude: floor.nw_latitude,
            validNWLat: true,
        });
        // console.log("floorId:", floor.id);
    }
    
    afterOpenEditFloorModal() {
    // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }
        
    viewAddFloor(floor) {
        this.setState({
            adding_floor: true,
            currentFloor:floor.id,
        }); 
    }
    
    afterOpenAddFloorModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    addEditComplete = () => {
        this.setState({
            editing_floor: false,
            adding_floor: false,
            edit_changes_made: false,
            floorId:"",
            floorLevel:"",
            floorName:"",
            floorplan:"",
            floorplanData:"",
            file_size:"",
            mime_type: "",
            floorPixelHeight: 0,
            floorPixelWidth: 0,
            floorRealHeight: "",
            validHeight: false,
            floorRealWidth: "",
            validWidth: false,
            swLongitude: "",
            validSWLon: false,
            swLatitude: "",
            validSWLat: false,
            nwLongitude: "",
            validNWLon: false,
            nwLatitude: "",
            validNWLat: false,
        });
    }
    
    closeModal() {
        if (this.state.addModalIsOpen) {
            if(this.state.floorName||this.state.floorLevel||this.state.floorPixelHeight||this.state.floorPixelWidth||this.state.floorplanData) {
                if(window.confirm("You have made changes to this form.  Do you want to close without saving?")) {
                    this.setState({
                        editFloorModalIsOpen: false,
                        addFloorModalIsOpen: false,
                        edit_changes_made: false,
                        floorId:"",
                        floorLevel:"",
                        floorName:"",
                        floorplan:"",
                        floorplanData:"",
                        file_size:"",
                        mime_type: "",
                        floorPixelHeight: 0,
                        floorPixelWidth: 0,
                        floorRealHeight: "",
                        validHeight: false,
                        floorRealWidth: "",
                        validWidth: false,
                        swLongitude: "",
                        validSWLon: false,
                        swLatitude: "",
                        validSWLat: false,
                        nwLongitude: "",
                        validNWLon: false,
                        nwLatitude: "",
                        validNWLat: false,
                    });
                }
            }
        }
        else if (this.state.editModalIsOpen) {
            if (!this.state.edit_changes_made) {
                if (window.confirm("You have made changes to this floor.  Do you want to close without saving?")) {
                    this.setState({
                        editFloorModalIsOpen: false,
                        addFloorModalIsOpen: false,
                        edit_changes_made: false,
                        floorId:"",
                        floorLevel:"",
                        floorName:"",
                        floorplan:"",
                        floorplanData:"",
                        file_size:"",
                        mime_type: "",
                        floorPixelHeight: 0,
                        floorPixelWidth: 0,
                        floorRealHeight: "",
                        validHeight: false,
                        floorRealWidth: "",
                        validWidth: false,
                        swLongitude: "",
                        validSWLon: false,
                        swLatitude: "",
                        validSWLat: false,
                        nwLongitude: "",
                        validNWLon: false,
                        nwLatitude: "",
                        validNWLat: false,
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.getFloors();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    getFloors = () => {                  

        // console.log("site id", site_id);
        fetch(`${database}/floors/by_site?token=${token}&site_id=${site_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (floors) => {
            if (floors.data.length>0){
                // console.log("site", site_id, "has", floors.data.length, "floors");                       
                let floors_array = [];
                for (let j=0; j<floors.data.length; j++) {
                    let floor = {
                        id:floors.data[j].id,
                        name:floors.data[j].name,
                        site_id:floors.data[j].site_id,
                        level:floors.data[j].level,
                        floorplan:floors.data[j].floorplan,
                        mime_type:floors.data[j].mime_type,
                        file_size:floors.data[j].file_size,
                        pixel_height:floors.data[j].pixel_height,
                        pixel_width:floors.data[j].pixel_width,
                        real_height: floors.data[j].real_height,
                        real_width: floors.data[j].real_width,
                        sw_longitude: floors.data[j].sw_lon,
                        sw_latitude: floors.data[j].sw_lat,
                        nw_longitude: floors.data[j].nw_lon,
                        nw_latitude: floors.data[j].nw_lat,
                    }
                    floors_array.push(floor);
                }
                // console.log("floors array:", floors_array);
                this.setState({floors:floors_array});
            }
            else {
                console.log("site", site_id, "has no floors");
            }
        }).catch(function(err) {
            console.log(err);
        });      
    }

    addFloor() {
        if (!this.state.floorName) {
            alert("Please enter a name for the floor.");
            return;
        }
        else if (!this.state.floorLevel) {
            alert("Please enter a level for the floor.");
            return;
        }
        else if (!this.state.floorPixelHeight || !this.state.floorPixelWidth) {
            alert("Please enter aspect ratio dimenstions for the floor.");
            return;
        } else if (!this.state.floorRealHeight || !this.state.floorRealWidth){
            alert("One of your floorplan dimensions is missing.");
            return;
        }
        else if (!this.state.floorRealHeight || !this.state.floorRealWidth){
            alert("One of your floorplan dimensions is missing.")
            return;
        }
        else if (!this.state.floorplanData) {
            alert("Please choose an image file for the floorplan.");
            return;
        } else if (!this.state.swLongitude || !this.state.swLatitude || !this.state.nwLatitude || !this.state.nwLongitude ){
            alert("One or more of the coordinates is missing or incorrect format.");
            return;
        } 
        else if (!this.state.validSWLat || !this.state.validSWLon || !this.state.validNWLat || !this.state.validNWLon){
            alert("One or more of the coorinates is the incorrect format");
            return;
        }
        else {
            console.log("Edit Floor data is complete");
        }

        let data = {
            name: this.state.floorName,
            site: this.state.siteId,
            level: parseInt(this.state.floorLevel, 10),
            floorplanData: this.state.floorplanData,
            mime_type: "image/bmp",
            file_size: this.state.file_size, 
            floorPixelHeight: parseInt(this.state.floorPixelHeight, 10),
            floorPixelWidth: parseInt(this.state.floorPixelWidth, 10),
            floorRealHeight: this.state.floorRealHeight,
            floorRealWidth: this.state.floorRealWidth,
            swLatitude: this.state.swLatitude,
            swLongitude: this.state.swLongitude,
            nwLatitude: this.state.nwLatitude,
            nwLongitude: this.state.nwLongitude
        };

        // console.log("add floor data:", data);
      
        fetch(`${database}/floors/add?token=${token}&name=${data.name}&site_id=${data.site}&level=${data.level}&mime_type=${data.mime_type}
            &file_size=${data.file_size}&pixel_height=${data.floorPixelHeight}&pixel_width=${data.floorPixelWidth}
            &real_height=${data.floorRealHeight}&real_width=${data.floorRealWidth}
            &sw_lat=${data.swLatitude}&sw_lon=${data.swLongitude}
            &nw_lat=${data.nwLatitude}&nw_lon=${data.nwLongitude}`,
             {method:'POST', headers: {'Accept':'application/json', 'Content-Type': "application/json"}, body:JSON.stringify({image:this.state.floorplanData})})
            .then(this.getFloors)
            .catch(err => console.error(err));

        this.setState({
            currentSite:"", 
            floorName:"", 
            floorLevel:"", 
            floorplanData: "",
            floorplan:"", 
            mime_type:"",
            file_size:0,
            floorHeight:"", 
            floorWidth:""});
        this.addEditComplete();
    }
        
    editFloor() {
        // console.log("EDIT SITE ID IS::", this.site_id);

        if (!this.state.floorName) {
            alert("Please enter a name for the floor.");
            return;
        }
        else if (!this.state.floorLevel) {
            alert("Please enter a level for the floor.");
            return;
        }
        else if (!this.state.floorPixelHeight || !this.state.floorPixelWidth) {
            alert("Please enter aspect ratio dimenstions for the floor.");
            return;
        }
        else if (!this.state.floorRealHeight || !this.state.floorRealWidth){
            alert("One of your floorplan dimensions is missing.")
            return;
        }
        else if (!this.state.validHeight || !this.state.validWidth){
            alert("One of your floorplan dimensions is an incorrect format.");
            return;
        }
        else if (!this.state.floorplanData) {
            alert("Please choose an image file for the floorplan.");
            return;
        } 
        else if (!this.state.swLongitude || !this.state.swLatitude || !this.state.nwLatitude || !this.state.nwLongitude ){
            alert("One or more of the coordinates is missing.");
            return;
        }
        else if (!this.state.validSWLat || !this.state.validSWLon || !this.state.validNWLat || !this.state.validNWLon){
            alert("One or more of the coorinates is the incorrect format");
            return;
        }
        else {
            console.log("Edit Floor data is complete");
        }

        let editFloorplan = "";
        if (this.state.floorplanData !== this.state.floorplan) {
            editFloorplan = this.state.floorplanData;
            // console.log("Using new floorplan");
        }
        else if (this.state.floorplan) {
            editFloorplan = this.state.floorplan;
            // console.log("Using original floorplan");
        }
        else {
            console.log("No floorplan");
        }
        let data = {
            id: parseInt(this.state.floorId, 10),
            name: this.state.floorName,
            site_id: this.site_id,
            level: parseInt(this.state.floorLevel, 10),
            floorplan: editFloorplan,
            mime_type: "image/bmp",
            file_size: 0,
            floorPixelHeight: parseInt(this.state.floorPixelHeight, 10),
            floorPixelWidth: parseInt(this.state.floorPixelWidth, 10),
            floorRealHeight: this.state.floorRealHeight,
            floorRealWidth: this.state.floorRealWidth,
            swLatitude: this.state.swLatitude,
            swLongitude: this.state.swLongitude,
            nwLatitude: this.state.nwLatitude,
            nwLongitude: this.state.nwLongitude,
        }

        // console.log("edit floor data:", data);
        // update database
        fetch(`${database}/floors/edit?token=${token}&id=${data.id}&name=${data.name}&site_id=${data.site_id}&level=${data.level}&mime_type=${data.mime_type}
            &file_size=${data.file_size}&pixel_height=${data.floorPixelHeight}&pixel_width=${data.floorPixelWidth}
            &real_height=${data.floorRealHeight}&real_width=${data.floorRealWidth}
            &sw_lat=${data.swLatitude}&sw_lon=${data.swLongitude}
            &nw_lat=${data.nwLatitude}&nw_lon=${data.nwLongitude}`,
             {method:'POST', headers: {'Accept':'application/json', 'Content-Type': "application/json"}, body:JSON.stringify({image:editFloorplan})})
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then(function(data) { 
            // console.log("Edit Floor Response Data::", data);
            this.getFloors();
        }).catch(function(err) {
            console.log(err);
        });

        this.getFloors();
        this.addEditComplete();
        this.setState({
            floorName: "",
            level: "",
            floorplanData: "",
            floorplan: "",
            mime_type: "",
            file_size: 0,
            floorHeight: "",
            floorWidth: "",
            id: "",
        })
    }
    
    logChangeName = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const backslash = "\\";
        if (e.target.value.length>30){
            alert("Floor Name cannot exceed 30 characters");
            return;
        }
        else if (e.target.value.includes("#")||e.target.value.includes(backslash)||e.target.value.includes("+")||e.target.value.includes("'")) {
            alert("Floor name cannot include these special characters: #, ', \\, +");
            e.target.value = e.target.value.replace("#", "");
            e.target.value = e.target.value.replace("'", "");
            e.target.value = e.target.value.replace("\\", "");
            e.target.value = e.target.value.replace("+", "");
        }
        this.setState({
            floorName: e.target.value,
            edit_changes_made: true,
        });  
        // console.log(this.state.floorName);
    }

    logChangeLevel = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const floorLevel = (e.target.validity.valid) ? e.target.value : this.state.floorLevel;
        this.setState({ 
            floorLevel: floorLevel,
            edit_changes_made: true,
         });  
    }

    logChangePixelHeight = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const floorPixelHeight = (e.target.validity.valid) ? e.target.value : this.state.floorPixelHeight;
        this.setState({ 
            floorPixelHeight: floorPixelHeight,
            edit_changes_made: true,
        });  
    }

    logChangePixelWidth = (e) => {
        // console.log("event",e.target.name, e.target.value);
        const floorPixelWidth = (e.target.validity.valid) ? e.target.value : this.state.floorPixelWidth;
        this.setState({ 
            floorPixelWidth: floorPixelWidth,
            edit_changes_made: true,
         });  
    }

    logChangeFloorplanHeight = (e) => {
        const floorplanHeight = (e.target.validity.valid) ? e.target.value : this.state.floorRealHeight;
        this.setState({floorRealHeight: floorplanHeight,})
        if(this.validateRealDimension(floorplanHeight)){
            console.log("Valid height dimension");
            this.setState({
                edit_changes_made: true,
                validHeight: true
            });
        }
        else {
            console.log("Invalid height dimension");
            this.setState({
                validHeight: false,
                edit_changes_made: false
            })
        }
    }

    logChangeFloorplanWidth = (e) => {
        const floorplanWidth = (e.target.validity.valid) ? e.target.value : this.state.floorRealWidth;
        this.setState({floorRealWidth: floorplanWidth,})
        if(this.validateRealDimension(floorplanWidth)){
            console.log("Valid width dimension");    
            this.setState({
                edit_changes_made: true,
                validWidth: true
            })
        } 
        else {
            console.log("Invalid width dimension");
            this.setState({
                validWidth: false,
                edit_changes_made: false
            })
        }
    }

    logChangeSWLon = (e) => {
        const swLon = (e.target.validity.valid) ? e.target.value : this.state.swLongitude;
        this.setState({swLongitude: swLon});
        if(this.validateCoordinate(swLon)){
            console.log("Valid SW Longitude");
            this.setState({
                validSWLon: true,
                edit_changes_made: true
            })
        } else {
            console.log("Invalid SW Longitude");
            this.setState({
                validSWLon: false,
                edit_changes_made: false
            })
        }
    }

    logChangeSWLat = (e) => {
        const swLat = (e.target.validity.valid) ? e.target.value : this.state.swLongitude;
        this.setState({swLatitude: swLat});
        if(this.validateCoordinate(swLat)){
            console.log("Valid SW Latitude");
            this.setState({
                validSWLat: true,
                edit_changes_made: true
            })
        } else {
            console.log("Invalid SW Latitude");
            this.setState({
                validSWLat: false,
                edit_changes_made: false
            })
        }
    }

    logChangeNWLon = (e) => {
        const nwLon = (e.target.validity.valid) ? e.target.value : this.state.swLongitude;
        this.setState({nwLongitude: nwLon});
        if(this.validateCoordinate(nwLon)){
            console.log("Valid NW Longitude");
            this.setState({ 
                validNWLon: true,
                edit_changes_made: true
            })
        } else { 
            console.log("Invalid NW Longitude");
            this.setState({
                validNWLon: false,
                edit_changes_made: false
            })
        }

    }

    logChangeNWLat = (e) => {
        const nwLat = (e.target.validity.valid) ? e.target.value : this.state.nwLatitude;
        this.setState({nwLatitude: nwLat});
        if(this.validateCoordinate(nwLat)){
            console.log("Valid NW Latitude");
            this.setState({
                validNWLat: true,
                edit_changes_made: true
            })
        } else { 
            console.log("Invalid NW Latitude"); 
            this.setState({
                validNWLat: false,
                edit_changes_made: false
            })
        }
    }

    validateCoordinate = (coordString) => {
        const coordReg = new RegExp("^[0-9]{1,3}\.{1}[0-9]{1,7}$");
        return coordReg.test(coordString);
    }

    validateRealDimension = (dimenString) => {
        const dimenReg = new RegExp("^[0-9]+(\.{0,1}?[0-9]{1,2})?$");
        return dimenReg.test(dimenString);
    }
    

    previewFile = () => {
        let that = this;
        var preview = document.getElementById("floorplan-preview");
        var file    = document.querySelector('input[type=file]').files[0];
        var reader  = new FileReader();
        var data = ""
        
        reader.addEventListener("load", function () {
            // console.log("reader result:", reader.result);  
            preview.src = reader.result;       
            data = reader.result.split(',')[1];
            that.setState({floorplanData: data});
        }, false);
        
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    render() {
        const {floors} = this.state;
        let site = site_id;   
        let back_text = "Return to Site List"; 
        return (
            <div id="floors-container">

                <Header/>

                <div className="page-title">{this.state.adding_floor||this.state.editing_floor?this.state.siteHeaderLabel2:this.state.siteHeaderLabel1}</div>
                <div className='content-container'>
                {!this.state.adding_floor&&!this.state.editing_floor&&
                <div id='floors-wrapper'>

                    <div className="top-button-row">
                        <div className="table-label">Floor List</div>
                        <Tooltip placement="top" title="Add Floor">
                            <div className="add-btn" onClick={this.viewAddFloor} />
                        </Tooltip>
                    </div>

                    {this.state.floors && this.state.floors.length > 0 &&
                    <table id="floor-table">
                        <tbody>
                            {floors.map(floor => 
                            <tr className="floor-row" key={floor.id}>
                                <td className="main-col">{floor.name}</td>
                                <td className="action-wrapper action-wrapper-3">

                                    <Tooltip placement="top" title="Edit Floor">
                                        <div className="table-btn edit-btn" onClick={() => this.viewEditFloor(floor)} />
                                    </Tooltip>
                                    
                                    <div className="manage-next-btn"
                                        onClick={()=>{
                                            sessionStorage.setItem("floor_id", floor.id);
                                            sessionStorage.setItem("floor_name", floor.name);
                                            window.location.href="/zones";
                                        }}
                                    >ZONES</div>

                                    <Tooltip placement="top" title="Delete Floor">
                                        <div className="table-btn delete-btn"                   
                                            onClick={() => {
                                                if (window.confirm("Are you sure you want to delete this floor? This will also remove its zones.")){
                                                    fetch(`${database}/floors/delete?token=${token}&id=${floor.id}`)
                                                    .then(this.getFloors)
                                                    .catch(err => console.error(err))
                                                }
                                            }}
                                        />
                                    </Tooltip>

                                </td>

                            </tr>
                            )}

                        </tbody>
                    </table>
                    }

                    <button 
                        className="setup-back-btn" 
                        id="back-to-sites"
                        onClick={() => {
                            window.location.href="/sites";
                        }}
                    >{back_text}</button>

                </div>
                }

                {this.state.adding_floor&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Add Floor</div>
                        <div className="disabled-add-btn"/>
                    </div>
                    <div className="add-edit-form">

                        <div className="input-section">
                        <Divider className='form-section-divider' variant='middle' textAlign='center' sx={{fontWeight: 'bold', marginBottom: '10px'}}>General Info</Divider>
                        <Grid container spacing={2} className='input-field' >                        
                                <Grid item xs={6}> 
                                    <TextField
                                    label='Name'   
                                    placeholder='ex: Basement'
                                    onChange={this.logChangeName}
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                    label='Level'   
                                    placeholder='ex: 0'
                                    onChange={this.logChangeLevel}
                                    type='number'
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        
                        <div className="input-section">
                            <Divider className='form-section-divider' variant='middle' textAlign='center' sx={{fontWeight: 'bold', marginBottom: '10px'}}>Floorplan Aspect Ratio </Divider>
                            <Grid container spacing={2} className='input-field' sx={{marginTop: '10px'}}>                        
                                <Grid item xs={6}> 
                                    <TextField
                                    label='Height (pixels)'   
                                    placeholder='ex: 2'
                                    onChange={this.logChangePixelHeight}
                                    type='number'
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    label='Width (pixels)'   
                                    placeholder='ex: 2.5'
                                    onChange={this.logChangePixelWidth}
                                    type='number'
                                    />
                                </Grid>
                            </Grid>
                                                            
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<FileUploadIcon />}
                                sx={{marginTop: '10px'}}>
                                Upload Floorplan Image
                                <HiddenFileInput
                                    multiple
                                    onChange={this.previewFile}
                                    type='file'
                                    ref={input => {this.fileInput = input}}
                                />  
                            </Button>
                            
                                <div className="img-container">
                                    <img 
                                        id="floorplan-preview" 
                                        src="" 
                                        alt="No floorplan uploaded" 
                                        
                                    />
                                </div>
                                                   
                        </div>
                        <div className='input-section'>
                            <Divider className='form-section-divider' variant='middle' textAlign='center' fontSize="14pt" sx={{fontWeight: 'bold', marginBottom: '10px'}}> GPS Coordinates </Divider>
                            <Typography className= 'form-section-header' variant='subtitle1' textAlign={"left"} sx={{textDecoration: 'underline', marginTop: '5px'}}>Southwest Corner</Typography>
                            <Grid container spacing={2} className='input-field' sx={{marginTop: '5px'}}>                        
                                <Grid item xs={6} > 
                                    <TextField
                                    error={!this.state.validSWLon}
                                    label='Longitude'   
                                    placeholder='ex: 43.1109'
                                    onChange={this.logChangeSWLon}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    error={!this.state.validSWLat}
                                    label='Latitude'   
                                    placeholder='ex: 43.1109'
                                    onChange={this.logChangeSWLat}
                                    />
                                </Grid>
                            </Grid>

                            <Typography className= 'form-section-header' variant='subtitle1' textAlign={"left"} sx={{textDecoration: 'underline', marginTop: '10px'}}>Northwest Corner</Typography>
                            <Grid container spacing={2} className='input-field' sx={{marginTop: '5px'}}>                        
                                <Grid item xs={6}> 
                                    <TextField
                                    error={!this.state.validNWLon}
                                    label='Longitude'   
                                    placeholder='ex: 43.1109'
                                    onChange={this.logChangeNWLon}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    error={!this.state.validNWLat}
                                    label='Latitude'   
                                    placeholder='ex: 43.1109'
                                    onChange={this.logChangeNWLat}
                                    />
                                </Grid>
                            </Grid>
                            
                        </div>
                        <div className='input-section'>
                        <Divider className='form-section-divider' variant='middle' textAlign='center' fontSize="14pt" sx={{fontWeight: 'bold', marginBottom: '10px'}}> Floorplan Dimensions </Divider>
                            <Grid container spacing={2} className='input-field' sx={{marginTop: '10px'}}>                        
                                <Grid item xs={6}> 
                                    <TextField
                                    label='Height (m)'   
                                    placeholder='ex: 2'
                                    onChange={this.logChangeFloorplanHeight}
                                    error={!this.state.validHeight}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    label='Width (m)'   
                                    placeholder='ex: 2.5'
                                    onChange={this.logChangeFloorplanWidth}
                                    error={!this.state.validWidth}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="cs-btn-group">
                            <button className="submit-btn cs-btn" onClick={this.addFloor}>Submit</button>
                            <button className="cancel-btn cs-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>

                    </div>
                </div>
                }

                {this.state.editing_floor&&
                <div className="add-edit-wrapper">
                    <div className="add-edit-header">
                        <div className="add-edit-header-text">Edit Floor</div>
                        <div className="disabled-edit-btn"/>
                    </div>
                    <div className="input-section">
                        <Divider className='form-section-divider' variant='middle' textAlign='center' sx={{fontWeight: 'bold', marginBottom: '10px'}}>General Info</Divider>
                        <Grid container spacing={2} className='input-field' sx={{marginTop: '10px'}}>                         
                                <Grid item xs={6}> 
                                    <TextField
                                    label='Name'   
                                    placeholder='ex: Basement'
                                    onChange={this.logChangeName}
                                    value={this.state.floorName}
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                    
                                    label='Level'   
                                    placeholder='ex: 0'
                                    onChange={this.logChangeLevel}
                                    type='number'
                                    value={this.state.floorLevel}
                                    />
                                </Grid>
                            </Grid>
                     </div>

                        
                        <div className="input-section">
                            <Divider className='form-section-divider' variant='middle' textAlign='center' sx={{fontWeight: 'bold', marginBottom: '10px'}}>Floorplan Aspect Ratio </Divider>
                            <Grid container spacing={2} className='input-field' sx={{marginTop: '10px'}}>                        
                                <Grid item xs={6}> 
                                    <TextField
                                    
                                    label='Height (pixels)'   
                                    placeholder='ex: 2'
                                    onChange={this.logChangePixelHeight}
                                    type='number'
                                    value={this.state.floorPixelHeight}
                                    />
                                </Grid>
                                {/* <input
                                        className="form-control gps-sw-lon" 
                                        onInput={this.logChangeWidth} 
                                        placeholder="Longitude"
                                        type='text'
                                        pattern="[0-9]*.[0-9]*"
                                        name="floorWidth"
                                        value={this.state.floorWidth}
                                    />  */}
                                <Grid item xs={6}>
                                    <TextField
                                    
                                    label='Width (pixels)'   
                                    placeholder='ex: 2.5'
                                    onChange={this.logChangePixelWidth}
                                    type='number'
                                    value={this.state.floorPixelWidth}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<FileUploadIcon />}
                            sx={{marginTop: '10px'}}
                            > 
                            Upload Floorplan Image
                            <HiddenFileInput
                                multiple
                                onChange={this.previewFile}
                                type='file'
                                ref={input => {this.fileInput = input}}
                            />
                            </Button>
                            <div className="img-container">
                            <img 
                                id="floorplan-preview" 
                                src={this.state.preview}
                                alt="No floorplan uploaded" 
                            />
                            </div>
                        </div>
                        <div className='input-section'>
                            <Divider className='form-section-divider' variant='middle' textAlign='center' fontSize="14pt" sx={{fontWeight: 'bold', marginBottom: '10px'}}> GPS Coordinates </Divider>
                            <Typography className= 'form-section-header' variant='subtitle1' textAlign={"left"} sx={{textDecoration: 'underline', marginTop: '5px'}}>Southwest Corner</Typography>
                            <Grid container spacing={2} className='input-field' sx={{marginTop: '5px'}}>                        
                                <Grid item xs={6}> 
                                    <TextField
                                    error={!this.state.validSWLon}
                                    label='Longitude'   
                                    placeholder='ex: 43.1109'
                                    onChange={this.logChangeSWLon}
                                    value={this.state.swLongitude}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    error={!this.state.validSWLat}
                                    label='Latitude'   
                                    placeholder='ex: 43.1109'
                                    onChange={this.logChangeSWLat}
                                    value={this.state.swLatitude}
                                    />
                                </Grid>
                            </Grid>

                            <Typography className= 'form-section-header' variant='subtitle1' textAlign={"left"} sx={{textDecoration: 'underline', marginTop: '10px'}}>Northwest Corner</Typography>
                            <Grid container spacing={2} className='input-field' sx={{marginTop: '5px', marginBottom: '5px'}}>                        
                                <Grid item xs={6}> 
                                    <TextField 
                                    error={!this.state.validNWLon}
                                    label='Longitude'   
                                    placeholder='ex: 43.1109'
                                    onChange={this.logChangeNWLon}
                                    value={this.state.nwLongitude}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    error={!this.state.validNWLat}
                                    label='Latitude'   
                                    placeholder='ex: 43.1109'
                                    onChange={this.logChangeNWLat}
                                    value={this.state.nwLatitude}
                                    />
                                </Grid>
                            </Grid>
                            
                        </div>
                        <div className='input-section'>
                            <Divider className='form-section-divider' variant='middle' textAlign='center' fontSize="14pt" sx={{fontWeight: 'bold', marginBottom: '10px'}}> Floorplan Dimensions </Divider>
                            <Grid container spacing={2} className='input-field' sx={{marginTop: '10px'}}>                        
                            <Grid item xs={6}> 
                                    <TextField
                                    label='Height (m)'   
                                    placeholder='ex: 2'
                                    onChange={this.logChangeFloorplanHeight}
                                    error={!this.state.validHeight}
                                    value={this.state.floorRealHeight}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                    label='Width (m)'   
                                    placeholder='ex: 2.5'
                                    onChange={this.logChangeFloorplanWidth}
                                    error={!this.state.validWidth}
                                    value={this.state.floorRealWidth}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="cs-btn-group">
                            <button className="submit-btn cs-btn" onClick={this.editFloor}>Submit</button>
                            <button className="cancel-btn cs-btn" onClick={this.addEditComplete}>Cancel</button>
                        </div>

                </div>
                }  
                </div>
                <Footer/>

            </div>
        );
    }
}

export default Floors;
