import React, { Component } from 'react';
import saveFile from 'save-as-file';
import '../common.css';
import './ContactReport.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Main from '../../components/ReportForm/Main.tsx'
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
import ReportForm from '../../components/ReportForm/ReportForm.tsx';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

var moment = require('moment');

const database = sessionStorage.getItem("database");

const pageTitle = "Contact Trace Report";

var surveyRecords = [];

// CSV headers
// var surveysCsvOut = "survey,pid,alias,site,date,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13\n";
var validationsCsvOut = "date,time,site,floor,zone,alert level,result\n";

class ContactReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            surveys: [],
            sites: [],
            report_data: [],
        };
    }

    componentDidMount() {
        this.getUsers();
        this.getSites();
        this.getFloors();
        this.getZones();
        // this.getSurveys();
        this.getValidations();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }
    
    getUsers = () => {
        fetch(`${database}/users/?token=${token}`)
        .then(response => response.json())
        .then(users => this.setState({ users: users.data}))
        .catch(err => console.error(err));        
    }
    
    getSites = () => {             
        fetch(`${database}/sites/?token=${token}`)
        .then(response => response.json())
        .then(sites => this.setState({ sites: sites.data }))
        .catch(err => console.error(err));        
    }
        
    getFloors = () => {             
        fetch(`${database}/floors/?token=${token}`)
        .then(response => response.json())
        .then(floors => this.setState({ floors: floors.data }))
        .catch(err => console.error(err));        
    }
        
    getZones = () => {             
        fetch(`${database}/zones/?token=${token}`)
        .then(response => response.json())
        .then(zones => this.setState({ zones: zones.data }))
        .catch(err => console.error(err));        
    }
    
    // getSurveys = () => {
    //     fetch(`${database}/surveys/?token=${token}`)
    //     .then(response => response.json())
    //     .then((surveys) => {
    //         if (surveys.data) {
    //             // console.log("SURVEYS.LENGTH::", surveys.data.length);
    //             this.setState({ surveys: surveys.data});
    //             // this.createSurveysReport(surveys.data);
    //             this.parseSurveyData(surveys.data);
    //         }
    //         else {
    //             alert("No survey data found.");
    //         }
    //     })
    //     .catch(err => console.error(err));        
    // }

    parseSurveyData = (data) => {
        // CSV header
        let surveysCsvOut = "survey,pid,alias,site,date,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13\n";

        for (let i = 0; i < data.length; i++) {
            var answersArray = [];
            var surveyItems = data[i].items;
            let answerString = "";
            let surveyName = ""
            if (data[i].survey_type === 1) {
                surveyName = "activity";
            }
            else if (data[i].survey_type === 2) {
                surveyName = "burden";
            }
            else if (data[i].survey_type === 3) {
                surveyName = "QOL";
            }
            else {
                surveyName = "survey type unknown";
            }
            surveyItems = surveyItems.substring(
                surveyItems.lastIndexOf("[") + 1,
                surveyItems.lastIndexOf("]")
            )
            // remove "{" characters
            surveyItems = surveyItems.replace(/{/g, "");
            // split into subtrings
            surveyItems = surveyItems.split("}, ");
            // remove trailing "}" character
            surveyItems[surveyItems.length-1] = surveyItems[surveyItems.length-1].replace(/}/g, "");
            // convert array of strings into array of objects
            if (surveyName === "activity") {
                var answerObj = {};
                var answerArray = [];
                var question = "";
                // Activity survey
                for (let x = 0; x < surveyItems.length; x++) {
                    answerObj = {};
                    answerArray = surveyItems[x].split(",");
                    question = answerArray[2].substring(
                        answerArray[2].lastIndexOf("=") + 1
                    );
                    var hours = answerArray[0].substring(
                        answerArray[0].lastIndexOf("=") + 1
                    );
                    var minutes = answerArray[1].substring(
                        answerArray[1].lastIndexOf("=") + 1
                    );
                    var tot_minutes = parseInt(minutes, 10) + (parseInt(hours, 10) * 60);
                    answerObj = {
                        question: question,
                        hours: parseInt(hours, 10),
                        minutes: parseInt(minutes, 10),
                        tot_minutes: tot_minutes,
                    }
                    answersArray.push(answerObj);
                    
                    answerString += tot_minutes + ",";
    
                }
                // padding the row with empty columns so it is the same length as longest survey (13)
                // this may be unnecessary with the new line character at the end of the surveyString below.
                answerString += ",,,,,,,";

            }
            else {
                // burden or QOA survey
                for (let x = 0; x < surveyItems.length; x++) {
                    answerObj = {};
                    answerArray = surveyItems[x].split(",");
                    question = answerArray[1].substring(
                        answerArray[1].lastIndexOf("=") + 1
                    );
                    var answer = answerArray[0].substring(
                        answerArray[0].lastIndexOf("=") + 1
                    );
                    answerObj = {
                        question: question,
                        answer: answer,
                    }
                    answersArray.push(answerObj);
                    
                    answerString += answer + ",";
    
                }
                // burden survey
                if (surveyName === "burden") {
                    // padding an empty column
                    answerString += ",";
                }

            }

            // get user info
            var alias = "";
            var pid = "";
            for (let u = 0; u < this.state.users.length; u++) {
                if (parseInt(this.state.users[u].id, 10) === parseInt(data[i].user_id, 10)) {
                    alias = this.state.users[u].userAlias;
                    pid = this.state.users[u].userPid;
                    if (pid === undefined || pid === "undefined") {
                        pid = "No PID";
                    }
                }
            }

            // get site info
            var siteName = "";
            let surveySiteID = parseInt(data[i].site_id, 10);
            // console.log("Record Site::", surveySiteID);
            for (let s = 0; s < this.state.sites.length; s++) {
                if (parseInt(this.state.sites[s].id, 10) === surveySiteID) {
                    siteName = this.state.sites[s].name;
                }
            }

            let parsedDate = data[i].created_at.split("T");
            let date = parsedDate[0];

            let surveyString =  surveyName + ","+ pid + "," + alias + "," + siteName + "," + date + "," + answerString + "\n";
            surveysCsvOut += surveyString;

            var surveyObj = {
                alias: alias,
                pid: pid,
                site_name: siteName,
                date: date,
                answers: answersArray,
            }
            surveyRecords.push(surveyObj);
        }
        // console.log("SURVEY ANSWERS ARRAY::", surveyRecords);
        // console.log(surveysCsvOut);
        this.setState({ 
            surveysFmt: surveyRecords,
            surveysCsv: surveysCsvOut,
        });
    }
    
    getValidations = () => {
        fetch(`${database}/validations/?token=${token}`)
        .then(response => response.json())
        .then((validations) => {
            if (validations.data) {
                console.log("VALIDATIONS.LENGTH::", validations.data.length);
                this.setState({ validations: validations.data});
                // this.createSurveysReport(validations.data);
                // this.parseValidationsData(validations.data);
            }
            else {
                alert("No validations data found.");
            }
        })
        .catch(err => console.error(err));        
    }

    parseValidationsData = () => {
        let data = this.state.validations;
        var sites = this.state.sites;
        var floors = this.state.floors;
        var zones = this.state.zones;
        
        // CSV header
        // let surveysCsvOut = "date,time,site,floor,zone,alert level,result\n";

        for (let i = 0; i < data.length; i++) {
            
            let date = moment(data[i].timestamp*1000).format("YYYY/MM/DD");
            let time = moment(data[i].timestamp*1000).format("HH:mm");
            let siteID = null;
            let floorID = null;
            let siteName = "";
            let floorName = "";
            let zoneName = "";
            let safetyLevel = "";
            let result = data[i].result;
            if (result === 1) {
                result = "correct"
            }
            else if (result === 2) {
                result = "incorrect"
            }
            else if (result === 3) {
                result = "unable to verify"
            }
            else {
                result = "unknown"
            }

            // get zone info
            let valZoneID = parseInt(data[i].zone_id, 10);
            for (let z = 0; z < zones.length; z++) {
                if (zones[z].id === valZoneID) {
                    zoneName = zones[z].name;
                    siteID = zones[z].site_id;
                    floorID = zones[z].floor_id;
                    safetyLevel = parseInt(zones[z].safety_level, 10);
                }
            }
            if (zoneName === "") {zoneName = "unknown"};

            if (safetyLevel === "") 
            {safetyLevel = "unknown"}
            else if (safetyLevel === 3) {
                safetyLevel = "safe"
            }
            else if (safetyLevel === 2) {
                safetyLevel = "warn"
            }
            else {
                safetyLevel = "alert"
            }

            // get site info
            if (siteID) {
                for (let s = 0; s < sites.length; s++) {
                    if (sites[s].id === siteID) {
                        siteName = sites[s].name;
                    }
                }
                if (siteName === "") {siteName = "unknown"};
            }
            else {siteName = "unknown"}
            
            // get floor info
            if (floorID && floors) {
                for (let f = 0; f < floors.length; f++) {
                    if (floors[f].id === floorID) {
                        floorName = floors[f].name;
                    }
                }
                if (floorName === "") {floorName = "unknown"};
            }
            else {floorName = "unknown"}

            let validationString =  date + "," + time + "," + siteName + "," + floorName + "," + zoneName + "," + safetyLevel + "," + result + "\n";
            // console.log("VALIDATION STRING::", validationString);
            validationsCsvOut += validationString;

        }
        console.log("CSV OUT::");
        console.log(validationsCsvOut);
        this.outputValidationsCsv();
    }
    
    outputSurveysCsv = () => {
        var file = new File([this.state.surveysCsv], "surveys.csv", {type: "text/plain"});
        saveFile(file, "surveys.csv");
    }

    render() {
        const {report_data} = this.state;
        
        return (
            <div id="survey-report-container">

                <Header />

                {/* <div className="page-title">{pageTitle}</div>  */}

                <div className="survey-reports-wrapper">
                    <Main/>
                </div>
                
                <Footer />
            </div>
        );
    }
}

export default ContactReport;
