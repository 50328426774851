import { Component, Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import Landing from './pages/Landing';
import Login from './pages/Login';
import ContactReport from './pages/ContactReport';
import ReportResults from './components/ReportForm/ReportResults.tsx';
import ReportSettings from './components/ReportForm/Settings.tsx';
// import Live from './pages/Live';
import Sites from './pages/Sites';
import Floors from './pages/Floors';
import Zones from './pages/Zones';
import Devices from './pages/Devices';
import Users from './pages/Users';
import Notifications from './pages/Notifications';
import Preferences from './pages/Preferences';
import Diagnostics from './pages/Diagnostics';
import Backup from './pages/Backup';
import Calibrations from './pages/Calibrations';
import Residents from "./pages/Residents";
require('dotenv').config();

// const site_id = sessionStorage.getItem("site_id");
// const floor_id = sessionStorage.getItem("floor_id");

const isLoggedIn = sessionStorage.getItem("isLoggedIn");
const security = sessionStorage.getItem("security");

class WebRoutes extends Component {

    setRoutes() {
        
        if (isLoggedIn === "true") {
            if (security === "0") {
                return(
                    <div>
                        <Route  path='/' element={<Landing/>}/>
                        <Route  path='/landing' element={<Landing/>} />
                        <Route  path='/login' element={<Login/>} />
                        {/* <Route  path='/live' element={<Live/>}/> */}
                        <Route  path='/sites' element={<Sites/>} />
                        <Route  path='/floors' element={<Floors />} />
                        <Route  path='/devices' element={<Devices/>}/>
                        <Route  path='/users' element={<Users/>}/>
                        <Route  path='/residents' element={<Residents/>}/>
                        <Route  path='/preferences' element={<Preferences/>}/>
                        <Route  path='/zones' element={<Zones/>} />
                        <Route  path='/preferences' element={<Preferences/>} /> 
                        <Route  path='/diagnostics' element={<Diagnostics/>} />
                        <Route  path='/calibrations' element={<Calibrations/>} />
                        <Route  path='/contact-report/*' element={<ContactReport/>}>
                            <Route path='/results' element={<ReportResults/>}/> 
                        </Route>
                        {process.env.REACT_APP_STATION?<Route  path='/backup' element={<Backup/>}/>:""}
                    </div>
                )
            }
            else if (security === "3") {
                return(
                    <div>
                        <Route  path='/' element={<Landing/>}/>
                        <Route  path='/landing' element={<Landing/>} />
                        <Route  path='/login' element={<Login/>} />
                        {/* <Route  path='/live' element={<Live/>}/> */}
                        <Route  path='/sites' element={<Sites />} />
                        <Route  path='/floors' element={<Floors />} />
                        <Route  path='/zones' element={<Zones/>} />
                        <Route  path='/diagnostics' element={<Diagnostics/>} />
                        <Route  path='/calibrations' element={<Calibrations/>} />
                    </div>
                )
            }
            else {
                return(
                    <div>
                        <Route  path='/' element={<Landing/>}/>
                        <Route  path='/landing' element={<Landing/>} />
                        <Route  path='/login' element={<Login/>} />
                        {/* <Route  path='/live' element={<Live/>}/> */}
                                          
                    </div>
                )
            }
        }
        else {
            return(
                <div>
                    <Route  path='/' element={<Landing/>}/>
                    <Route  path='/landing' element={<Landing/>} />
                    <Route  path='/login' element={<Login/>} />
                </div>
            )
        }
    }
    
    render() {
        if (isLoggedIn === "true") {
            console.log("security::", security);
            if (security === "0") {
                return (
                    
                    <Routes>
                        <Fragment>
                            <Route  path='/' element={<Landing/>}/>
                            <Route  path='/landing' element={<Landing/>} />
                            <Route  path='/login' element={<Login/>} />
                            {/* <Route  path='/live' element={<Live/>}/> */}
                            <Route  path='/sites' element={<Sites/>} />
                            <Route  path='/floors' element={<Floors />} />
                            <Route  path='/zones' element={<Zones/>} />
                            <Route  path='/devices' element={<Devices/>}/> 
                            <Route  path='/residents' element={<Residents/>}/>
                            <Route  path='/users' element={<Users/>}/>
                            <Route  path='/notifications' element={<Notifications/>}/>
                            <Route  path='/preferences' element={<Preferences/>}/>
                            <Route  path='/diagnostics' element={<Diagnostics/>} />
                            <Route  path='/calibrations' element={<Calibrations/>} />
                            <Route  path='/contact-report/*' element={<ContactReport/>}>
                            </Route>       
			                {process.env.REACT_APP_STATION?<Route  path='/backup' element={Backup}/>:""}
                        </Fragment>
                    </Routes>
                    
                )
            }            
            else if (security === "3") {
                return (
                    
                    <Routes>
                        <Fragment>
                            <Route  path='/' element={<Landing/>}/>
                            <Route  path='/landing' element={<Landing/>} />
                            <Route  path='/login' element={<Login/>} />
                            {/* <Route  path='/live' element={<Live/>}/> */}
                            <Route  path='/sites' element={<Sites/>} />
                            <Route  path='/floors' element={<Floors />} />
                            <Route  path='/zones' element={<Zones/>} />
                            <Route  path='/diagnostics' element={<Diagnostics/>} />
                            <Route  path='/calibrations' element={<Calibrations/>} />
                        </Fragment>
                    </Routes>
                    
                )
            }
            else {
                return (
                    
                        <Routes>
                            <Fragment>
                                <Route  path='/' element={<Landing/>}/>
                                <Route  path='/landing' element={<Landing/>} />
                                <Route  path='/login' element={<Login/>} />
                                {/* <Route  path='/live' element={<Live/>}/> */}
                                <Route  path='/notifications' element={<Notifications/>}/>
                            </Fragment>
                        </Routes>
                    
                )
            }
        }
        else {
            return ( 
                <Routes>
                    <Fragment>
                        <Route  path='/' element={<Landing/>}/>
                        <Route  path='/landing' element={<Landing/>} />
                        <Route  path='/login' element={<Login/>} />
                    </Fragment>
                </Routes>
            )
        }
    }
}

export default WebRoutes;
