import React, { Component } from 'react';
import './LoginHeader.css';
import logo from '../../images/ActivTrace Logo.png';
import back_arrow from '../../images/back_arrow_white_on_blue.png';
import header_dots from '../../images/Header Dots.png';

class LoginHeader extends Component {
    render() {
        return (
            <div id="login-header-wrapper">
                 <div id='logo-container'>
                    <img id="logo" src={logo} alt="ActivTrace"/>
                </div>      
                <img id='header-graphic' src={header_dots}/>
            </div>     
        )
    }
}

export default LoginHeader;
