import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Menu, MenuItem, Modal, Box, Typography, Tooltip, IconButton, Grid } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import {PDFObject} from 'react-pdfobject';
import logo from '../../images/ActivTrace Logo.png';
import header_dots from '../../images/Header Dots.png';
import './Header.css';
import zIndex from '@mui/material/styles/zIndex';

const customStyles = {
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 400,
    bgcolor: 'background.paper',
    border: '1px solid black',
    boxShadow: 24,
    p: 4,
  }
};

const headerTheme = createTheme({
  palette : {
    logoutButton: {
      main: '#BCBEC0'
    },
  }
})

const Header = () => {
  const [logoutModalIsOpen, setLogoutModalIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState({
    manage: null,
    calibrate: null,
    contact: null
  });
  const [currentPage, setCurrentPage] = useState('');
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const handleHelpOpen = () => {setHelpModalOpen(true);}
  const handleHelpClose = () => {setHelpModalOpen(false);}

  useEffect(() => {
    // Set the current page based on the URL path
    const path = window.location.pathname;
    if (path.includes('/sites') || path.includes('/residents') || path.includes('/users') || path.includes('/devices') || path.includes('/floors') || path.includes('/zones')) {
      setCurrentPage('manage');
    } else if (path.includes('/calibrations') || path.includes('/diagnostics')) {
      setCurrentPage('calibrate');
    } else if (path.includes('/contact-report') || path.includes('/preferences')) {
      setCurrentPage('contact');
    }
    // Modal.setAppElement('app');
  }, []);

  const handleMenu = (menu) => (event) => {
    setAnchorEl({ ...anchorEl, [menu]: event.currentTarget });
  };

  const handleClose = (menu) => () => {
    setAnchorEl({ ...anchorEl, [menu]: null });
  };

  const openLogoutModal = () => {
    setLogoutModalIsOpen(true);
  };

  const closeModal = () => {
    setLogoutModalIsOpen(false);
  };

  const logoutFunc = () => {
    sessionStorage.setItem("isLoggedIn", "false");
    sessionStorage.setItem("security", null);
    closeModal();
    window.location.href = "/";
  };

  return (
    <ThemeProvider theme={headerTheme}>
    <div id="header-wrapper">
      <div id="logo-container">
      <Grid container spacing={4} alignItems={"center"}>
        <Grid item xs = {2} >
          <Tooltip title="Website Help Guide">
            <IconButton color="inherit" onClick={handleHelpOpen}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs = {8}>
            <img id="logo" src={logo} alt="ActivTrace" />
        </Grid>
        <Grid item xs = {2} alignItems={"center"}>
          <Button className="header-item" onClick={openLogoutModal} variant='contained' color="logoutButton">Log Out</Button>
        </Grid>
      </Grid>
      </div>
      
      
      <img id='header-graphic' src={header_dots} alt="header dots" />
      <AppBar position="static" sx={{ backgroundColor: '#27AAE1' }}>
        <Toolbar sx={{ justifyContent: 'center', padding: 0 }}>
          <Button
            aria-controls="manage-menu"
            aria-haspopup="true"
            onClick={handleMenu('manage')}
            color="inherit"
            className={`nav-button ${currentPage === 'manage' ? 'highlight' : ''}`}
          >
            Manage
          </Button>
          <Menu
            id="manage-menu"
            anchorEl={anchorEl.manage}
            keepMounted
            open={Boolean(anchorEl.manage)}
            onClose={handleClose('manage')}
          >
            <MenuItem onClick={handleClose('manage')} component="a" href="/sites">Manage Sites</MenuItem>
            <MenuItem onClick={handleClose('manage')} component="a" href="/residents">Manage Residents</MenuItem>
            <MenuItem onClick={handleClose('manage')} component="a" href="/users">Manage Users</MenuItem>
            <MenuItem onClick={handleClose('manage')} component="a" href="/devices">Manage Devices</MenuItem>
          </Menu>
          
          <Button
            aria-controls="calibrate-menu"
            aria-haspopup="true"
            onClick={handleMenu('calibrate')}
            color="inherit"
            className={`nav-button ${currentPage === 'calibrate' ? 'highlight' : ''}`}
          >
            Calibrate Zones
          </Button>
          <Menu
            id="calibrate-menu"
            anchorEl={anchorEl.calibrate}
            keepMounted
            open={Boolean(anchorEl.calibrate)}
            onClose={handleClose('calibrate')}
          >
            <MenuItem onClick={handleClose('calibrate')} component="a" href="/calibrations">Calibrate Zones</MenuItem>
            <MenuItem onClick={handleClose('calibrate')} component="a" href="/diagnostics">Diagnostics</MenuItem>
          </Menu>
          
          <Button
            aria-controls="contact-menu"
            aria-haspopup="true"
            onClick={handleMenu('contact')}
            color="inherit"
            className={`nav-button ${currentPage === 'contact' ? 'highlight' : ''}`}
          >
            Contact Report
          </Button>
          <Menu
            id="contact-menu"
            anchorEl={anchorEl.contact}
            keepMounted
            open={Boolean(anchorEl.contact)}
            onClose={handleClose('contact')}
          >
            <MenuItem onClick={handleClose('contact')} component="a" href="/contact-report">New Contact Report</MenuItem>
            <MenuItem onClick={handleClose('contact')} component="a" href="/preferences">Preferences</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      

      <Modal
        id="logout-modal"
        open={logoutModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="logout"
        aria-labelledby='logout-modal-title'
      > 
        <Box sx={ 
          {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          height: 400,
          margin: '10px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 0,
          borderRadius: '5px',
        }
        }>
          
          <div id="logout-modal-content">
          <Typography id='logout-modal-title' className='modal-header' variant='h4' component='h2' marginBottom={0} marginTop={'10px'}>
            Logout
          </Typography>
            <div className="log-message"><p>Are you sure you want to logout?</p></div>
            <div id='logout-buttons'>
            <button id="logout-btn" onClick={logoutFunc}>Logout</button>
            <button id="cancel-logout-btn" onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
           open={helpModalOpen}
           onClose={handleHelpClose}
           aria-labelledby="help-modal-title"
           aria-describedby="help-modal-description"
         >
           <Box sx={{
             position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: '80%',
             height: '80%',
             bgcolor: 'background.paper',
             boxShadow: 24,
             p: 4,
             borderRadius: 2,
           }}>
             <IconButton
               sx={{
                 position: 'absolute',
                 right: 8,
                 top: 8,
                 color: (theme) => theme.palette.grey[500],
               }}
               onClick={handleHelpClose}
             >
               <CloseIcon />
             </IconButton>
             <Typography id="help-modal-title" variant="h6" component="h2" gutterBottom align="center">
               User Guide
             </Typography>
             <Box id = "user-guide-container" sx={{ width: '100%', height: 'calc(100% - 40px)', overflow: 'auto' }}>
               <PDFObject
                 url = "./UserGuide.pdf"
                 id="user-guide-pdf"
                 containerId = "user-guide-container"
                 height="100%" 
               />
             </Box>
           </Box>
         </Modal>
    </div>
    </ThemeProvider>
  );
};

export default Header;