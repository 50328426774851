import { Component} from 'react';
import './App.css';
import WebRoutes from "./Routes";

class App extends Component {
    render() {
        return ( 
            <div id='app' className="App"> 
                <WebRoutes />
            </div>
        );
    }
}

export default App;
