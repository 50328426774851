    import React, { Component } from 'react';
    import './Footer.css';
    import asterlabslogo from '../../images/asterlabslogo@4x.png';

    class Footer extends Component {

        render() {
            return (
            <div id="footer-wrapper">
                <div id='footer-content-container'>
                    <img id="asterlabs-logo" src={asterlabslogo} alt="ASTER Labs"/>
                    <span>ActivTrace ver a1.1.0 ©2024</span>
                </div>
            </div> 
            );
        }
    }

    export default Footer;
