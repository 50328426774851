import React, { Component } from 'react';
import './Landing.css';
import '../common.css';
import Footer from '../../components/Footer';

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_info: false,
        };
    }

    toggleInfoLogin = () => {
        var toggle = !this.state.show_info;
        this.setState({show_info: toggle});
    }

    render() {
        return (
            <div className="landing-wrapper">
                {/* <div 
                    className="close-btn"
                    onClick={this.toggleInfoLogin} 
                /> */}
                <div 
                    className={this.state.show_info?"logo half-logo":"logo full-logo"} />
                <div className="context-wrapper">
                    {this.state.show_info?
                    <div className="about-text">
                        This Phase II SBIR project will enhance a wireless WiFi-based insole and investigate its efficacy in reducing caregiver activity and burden, and improving caregiver quality of life.                        
                    </div>
                    :
                    <button 
                        className="begin-btn"
                        onClick={()=>{window.location.href="/login"}}
                    >Begin</button>
                    }
                </div>
		        <text className="about-text" style={{display:'block', paddingTop:'20px'}}>The user understands and acknowledges that the computer and the network are the property of the University of Florida and ASTER Labs. The user agrees to comply with the University of Florida Acceptable Use Policy and Guidelines. Unauthorized use of this system is prohibited and subject to criminal and civil penalties. The university monitors computer and network activities without user authorization, and the university may provide information about computer or network usage to university officials, including law enforcement when warranted. Therefore, the user should have limited expectations of privacy.</text>
                <Footer/>
            </div>
        );
    }
}

export default Landing;
