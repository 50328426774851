import React, { Component } from 'react';
import '../common.css';
import './Notifications.css';
import { DatePicker } from 'antd';
import { createSecureContext } from 'tls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};
var Datetime = require('react-datetime');
var moment = require('moment');

let login_user_id = sessionStorage.getItem("user_id");
let user_security = sessionStorage.getItem("security");
const database = sessionStorage.getItem("database");
let login_user_site = null;

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            devices: [],
            sites: [],
            users: [],
            notifications: [],
            start_time: null,
            end_time: null,
            duration: 0,
            start_selected: null,
            end_selected: null,
            now: moment(),
        };
        this.getUserNotifications = this.getUserNotifications.bind(this);
        this.buildNotifications = this.buildNotifications.bind(this);
        this.addNotifications = this.addNotifications.bind(this); 
        this.clearNotifications = this.clearNotifications.bind(this);
    }

    componentDidMount() {
        this.getSites();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearRefreshInterval(refreshInterval);
    }

    getSites = () => { 
        fetch(`${database}/sites/?token=${token}`) 
            .then(response => response.json())
            .then(sites => this.setState({ sites: sites.data }))
            // .then(console.log("getSites:", this.state.sites))
            .catch(err => console.error(err));        
    } 

    getUsersForSite = (site) => {
        // console.log("Site Index:", site);
        let site_id = this.state.sites[site].id;
        let user_id = null;
        // if user is not admin, skip getting users for dropdown.  Use logged in user.
        if (user_security>0) {
            user_id = login_user_id;
            login_user_site = site_id;
            // console.log("Not admin.  User:", user_id, ", Site:", login_user_site);
            this.setState({
                site_id: login_user_site,
                user_id: login_user_id,
                // tableData: [],
            }, this.getUserNotifications());            
            return;
        }
        else {
            // console.log("Getting users for site:", site);
            fetch(`${database}/users/users_by_site?token=${token}&site_id=${site_id}`)
                .then(response => response.json())
                .then(users => this.setState({ users: users.data}))
                // .then(console.log("getUsersForSite:", this.state.users))
                .catch(err => console.error(err));        
                this.setState({
                    site_id: site_id,
                    user_id: user_id,
                    // tableData: [],
                });
        }
    }

    
    getUserNotifications(user) {
        // console.log("USER:", user);
        let user_id;
        let site_id = this.state.site_id;
        if (user) {
            user_id = this.state.users[user].id;
        }
        else {
            if (this.state.user_id) {
                user_id = this.state.user_id;
            }
            else {
                user_id = login_user_id;
                site_id = login_user_site;
            }
        }
        // console.log("Site is:", site_id);
        // console.log("getting notifications for:", user_id);
        this.setState({
            notifications:[],
            user_id: user_id,
            start_time: null,
            end_time: null,
            duration: 0,
            start_selected: null,
            end_selected: null,
            now: moment(),
        });
        fetch(`${database}/notifications/user?token=${token}&user_id=${user_id}`)
        .then( response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            return response.json();
        })
        .then( (response) => {
            if (response.data.length > 0) {
                // console.log("There are", response.data.length, " notifications for user:", user_id);
                let tempArray = [];
                for (let i=0; i<response.data.length; i++) {
                    // console.log("RESPONSE:", response.data[i]);
                    tempArray.push(response.data[i].device_id);
                    let start = moment(response.data[i].start_time);
                    let end = moment(response.data[i].end_time);
                    let duration = end.from(start).replace(/\D/g, "");
                    // console.log("DURATION::", duration);
                    this.setState({
                        start_time: start,
                        end_time: end,    
                        duration: parseInt(duration),                    
                    })
                }
                this.setState({notifications:tempArray});
            }
        })
        .then(this.loadNotificationsTable())
        .catch(function(err) {
            console.log(err);
        }); 
    }

    loadNotificationsTable() {
        this.setState({
            tableData:[],
        });
        // console.log("loading tableData");
        let site_id = null;
        if (this.state.site_id) {
            site_id = this.state.site_id;
        }
        else {
            site_id = login_user_site;
        }
        // get devices for selected site
        fetch(`${database}/devices/site?token=${token}&site_id=${site_id}`)
        .then(response => response.json())
        .then((devices) => {
            // console.log("devices for site ID:",site_id,":",devices );
            let temp_tableData = [];
            for (let i = 0; i< devices.data.length; i++) {
                // console.log("fetching patient:", devices.data[i].patient_id)
                let patient_id = devices.data[i].patient_id;
                fetch(`${database}/patients/patient?token=${token}&id=${patient_id}`)
                .then(response => response.json())
                .then((patient) => {
                    let notification = {
                        device_id:devices.data[i].id,
                        patient_id:patient.data[0].id,
                        patient_alias:patient.data[0].alias,
                    }
                    // console.log("notification:", notification);
                    temp_tableData.push(notification);
                    this.setState({tableData:temp_tableData});
                    // console.log("temp tableData:", this.state.tableData)
                })
                .catch(err => console.error(err));               
            }
            
        })
        .catch(err => console.error(err));
        
    }

    buildNotifications(event) {
        let device = parseInt(event.target.value, 10);
        let temp_data = this.state.notifications;
        if (temp_data.indexOf(device)===-1){
            // add device to user notifications in state
            temp_data.push(device);
            this.setState({notifications:temp_data});
        }
        else {
            // remove device from user notifications in state
            temp_data = temp_data.filter(entry => entry !== device);
            this.setState({notifications:temp_data});
        }
    }

    addNotifications() {
        let user_id = this.state.user_id;
        let tempDevices = this.state.notifications;
        let start;
        let end;
        if (this.state.start_time) {
            start = this.state.start_time;
        }
        else {
            start = this.state.now;
        }        
        if (this.state.end_time&&this.state.end_time!==this.state.start_time) {
            end = this.state.end_time;
        }
        else {
            end = start.add(8, 'hours');
        }
        let startUTC = start.valueOf();
        let endUTC = end.valueOf();
        // console.log("TIMES:", startUTC, endUTC);
        // first, delete existing notifications for this user
        fetch(`${database}/notifications/delete_by_user?token=${token}&user_id=${user_id}`)
        .then(console.log("deleted notifications for user:", user_id))
        .catch( err => console.log(err));
        // then add all new notifications
        if (tempDevices.length > 0) {
            for (let i=0; i<tempDevices.length; i++) {
                let device = tempDevices[i];
                let start_time = startUTC;
                let end_time = endUTC;
                // console.log("adding notifications for user:", user_id);
                // console.log("device is:", device);
                // console.log("start timestamp is:", start_time);
                // console.log("end timestamp is:", end_time);
                fetch(`${database}/notifications/add?token=${token}&device_id=${device}&user_id=${user_id}&start_time=${start_time}&end_time=${end_time}`)
                .catch(err => console.error(err));
            }
            this.getUserNotifications();
        }
        else {
            this.setState({
                duration: 0,
                start_time: moment(),
                end_time: null,
            })
        }
    }

    clearNotifications() {
        let user_id = this.state.user_id;
        // delete existing notifications for this user
        fetch(`${database}/notifications/delete_by_user?token=${token}&user_id=${user_id}`)
        .then(console.log("deleted notifications for user:", user_id))
        .catch( err => console.log(err));
        this.getUserNotifications();
    }

    getStartTime = (value) => {
        // console.log("getStartTime:", value);
        // console.log("Start Time:", value.format("MM-DD-YYYY HH:mm a"));
        this.setState({
            start_time: value,
        })
    }

    validStart = ( value ) => {
        let yesterday = Datetime.moment().subtract( 1, 'day' );
        return value.isAfter( yesterday );
    }

    setStartTime = () => {
        this.setState({
            start_selected: true,
        })
    }

    getShiftDuration = (hours) => {
        // console.log("DURATION:", hours);
        let startTime = this.state.start_time;
        let endTime = new moment(startTime);
        endTime = endTime.add(hours, 'hours');
        // console.log("START Time:", endTime.format("MM-DD-YYYY HH:mm a"));        
        // console.log("End Time:", endTime.format("MM-DD-YYYY HH:mm a"));
        this.setState({
            duration: hours,
            end_time: endTime,
            end_selected: true,
        })
        // console.log("START TIME:", startTime.format("MM-DD-YYYY HH:mm a"));
    }

    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day').subtract(24, 'hours');
    }

    render() {
        
        return (
            <div id="notifications-container">

                <Header/>

                <div className="page-title">Manage Notifications</div>

                <div className="notifications-page-wrapper">

                    <div id="notifications-selects-wrapper">

                        <div className="notification-select-group notifications-site-group">
                            <div className="notifications-select-label">Site</div>
                            <select id="notifications-site-select" onChange={(event) =>{this.getUsersForSite(event.target.value)}} defaultValue={99}>
                                <option value={99} disabled>Select a Site</option>
                                {this.state.sites && this.state.sites.map((value, index) => {
                                    if(value.name !== "All Sites") {
                                        return (
                                        <option value={index} key={index}>{value.name}</option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                        
                        {this.state.site_id && parseInt(user_security, 10)===0 &&
                        <div className="notification-select-group">
                        <div className="notifications-select-label">User</div>
                            <select id="notifications-user-select" onChange={(event) =>{this.getUserNotifications(event.target.value)}} defaultValue={99}>
                                <option value={99} disabled>Select a User</option>
                                {this.state.users && this.state.users.length > 0 ? 
                                this.state.users.map((value, index) => {
                                    return (
                                    <option value={index} key={index}>{value.userAlias}</option>
                                    )
                                })
                                :
                                <option value="0" disabled>Assign users to this site</option>
                                }
                            </select>                        
                        </div>
                        }

                        {this.state.user_id &&

                            <div className="shift-range-select-grp">
                                <div id="start-time-select" className="notification-select-group">
                                    <div className="notifications-select-label">Start Time</div>
                                    <DatePicker 
                                        showTime={{format:"HH:mm"}}
                                        placeholder="Select Start Time" 
                                        onChange={this.getStartTime} 
                                        format="YYYY-MM-DD HH:mm"
                                        disabledDate={this.disabledDate}
                                    />
                                </div>
                                
                                <div className="notification-select-group">
                                    <div className="notifications-select-label">Duration</div>
                                    <select 
                                        name="duration-select" 
                                        id="duration-select" 
                                        value={this.state.duration}
                                        onChange={(event) =>{this.getShiftDuration(event.target.value)}} 
                                    >
                                        <option value="0">Set duration</option>
                                        <option value="1">1 hour</option>
                                        <option value="2">2 hours</option>
                                        <option value="3">3 hours</option>
                                        <option value="4">4 hours</option>
                                        <option value="5">5 hours</option>
                                        <option value="6">6 hours</option>
                                        <option value="7">7 hours</option>
                                        <option value="8">8 hours</option>
                                        <option value="9">9 hours</option>
                                        <option value="10">10 hours</option>                     
                                    </select>
                                </div>
                                
                            </div>

                        }

                    </div>

                    {this.state.site_id && this.state.user_id && 
                    <div id="notifications-table-container">
                        <div className="notifications-table-top-row">
                            <div id="notifications-table-label">Choose patient(s)</div>
                            <button 
                                id="unselect-btn"
                                onClick={this.clearNotifications}
                            >Unselect All</button>
                        </div>
                        <div id="notifications-table-wrapper">
                            <table id="notifications-table">
                                <tbody>
                                    {this.state.tableData && this.state.tableData.map((notification, index) =>
                                    <tr className="notification-row" key={index}> 
                                        {this.state.notifications.indexOf(notification.device_id) === -1?
                                        <input 
                                            type="checkbox" 
                                            name="notification-cBox" 
                                            value={notification.device_id}
                                            onChange={this.buildNotifications}
                                        />
                                        :
                                        <input 
                                            type="checkbox" 
                                            name="notification-cBox" 
                                            value={notification.device_id}
                                            onChange={this.buildNotifications}
                                            checked
                                        />
                                        }
                                        <label htmlFor="notification-cBox">{notification.patient_alias}</label>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div id="notifications-buttons-container">
                            <button 
                                id="notifications-submit-btn" 
                                onClick={this.addNotifications}
                            >Submit</button>
                        </div>
                    </div>
                    }
                    
                </div>

                <Footer/>

            </div>
        );
    }
}

export default Notifications;
