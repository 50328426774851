import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import '../common.css';
import './Diagnostics.css';
import './Collapsible.css';
import Modal from 'react-modal';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
var refreshInterval = null;
var token = sessionStorage.getItem("token");
var refreshAccessToken = function(value) {token = value};

// boilerplate styling for react-modal
const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      marginTop: '10px',
      transform: 'translate(-50%, -50%)',
      width: '400px', 
      height: '262px',
      border: '1px solid black',
      borderRadius: 'none',
    }
};

var moment = require('moment-timezone');
var sites_promise = [];
var patients_promise = [];
var mobile_hotspot_threshold = 2
const database = sessionStorage.getItem("database");
const security = sessionStorage.getItem("security");
const user_id = sessionStorage.getItem("user_id");
let user_site = "";
// Interval function handle
var interval_handle = null;

class Diagnostics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            diagnosticsHeaderLabel: "Diagnostics",
            zones: null,
        };
        this.openResetModal = this.openResetModal.bind(this);
        this.afterOpenResetModal = this.afterOpenResetModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        if (security !== "0") {
            this.getUserSite();
        }
        this.getCalibratedZones();
        setRefreshInterval(database, refreshInterval, refreshAccessToken);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearRefreshInterval(refreshInterval);
    }

    getUserSite() {
        fetch(`${database}/users/userId?token=${token}&user_id=${user_id}`)
        .then(response => response.json())
        .then(user => {
            let site_id = user.data[0].site_id;
            fetch(`${database}/sites/site?token=${token}&site_id=${site_id}`)
            .then(response => response.json())
            .then((sites) => {
                // console.log("SITE DATA", sites.data[0]);
                user_site = sites.data[0].id;
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err)); 
    }
    
    openResetModal(zone) {
        this.setState({
            resetModalIsOpen: true,
            reset_zone_ID: zone.zone_id,
            reset_zone_name: zone.zone_name,
        });
    }
    
    afterOpenResetModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }
    
    closeModal() {
        this.setState({
            resetModalIsOpen: false,
            reset_zone_ID: null,
            reset_zone_name: null,
        });
    }

    getDevices = (site_id) => {
        let that = this;
        // get devices for site
        console.log("getDevices for site::", site_id );
        // refresh devices every minute
        if (interval_handle) {
            clearInterval(interval_handle);
        }
        interval_handle = setInterval(function(){
            fetch(`${database}/devices/site/?token=${token}&site_id=${site_id}`)
            .then(response => response.json())
            .then((devices) => {
                let temp_devices = [];
                for (let i=0; i<devices.data.length; i++) {
                    let isConnected = false;
                    if (devices.data[i].is_connected === 1) {
                        isConnected = true;
                    }
                    // console.log("last connected::", devices.data[i].last_connected);
                    let connectedAt = moment.tz(devices.data[i].last_connected, "America/Chicago").format('DD-MMM-YYYY, hh:mm A');
                    temp_devices.push({
                        id:devices.data[i].id, 
                        name:devices.data[i].name,
                        connected:isConnected,
                        last_connected: connectedAt,
                    })
                }
                // console.log("getDevices temp_devices::", temp_devices);
                that.setState({ 
                    devices: temp_devices,
                })                
            })
            // .then(console.log("getDevices::", this.state.devices));
            .catch(err => console.error(err));
        }, 30000);
    }

    getCalibratedZones = () => {
        // fill an array of unique zones from calibration records
        let calibrated_zones = [];
        let calibrated_zones_data = [];
        let sites = [];
        let sites_data = [];
        fetch(`${database}/calibrations/zones/?token=${token}`)
        .then(response => response.json())
        .then((zoneIDs) => {
            let zone_id = zoneIDs.data;
            for (let i=0; i<zone_id.length; i++) {
                calibrated_zones.push(Object.values(zone_id[i])[0])
            }
        })
        .then( () => {
            if (calibrated_zones) {
                // look up each zone to build arrays of unique sites
                for (let i2=0; i2<calibrated_zones.length; i2++) {
                    let zone_id = calibrated_zones[i2];
                    fetch(`${database}/zones/find?token=${token}&id=${zone_id}`)
                    .then(response => response.json())
                    .then((zone) => {
                        let calibrated_zone_data = {
                            zone_id: zone_id,
                            site_id: zone.data[0].site_id,
                        }
                        calibrated_zones_data.push(calibrated_zone_data);
                        let site_id = zone.data[0].site_id;
                        // look up site to get name
                        fetch(`${database}/sites/site?token=${token}&site_id=${site_id}`)
                        .then( response => {
                            if (response.status >= 400) {
                                throw new Error("Bad response from server");
                            }
                            return response.json();
                        })
                        .then( (site) => {
                            console.log("SITE DATA::", site.data[0]);
                            let site_id = site.data[0].id;
                            let site_name = site.data[0].name;
                            console.log("SECURITY::", security);
                            console.log("USER_SITE::", user_site);
                            console.log("SITE::", site_id);
			    console.log(sites.indexOf(site_id))
                            if (sites.indexOf(site_id) === -1) {
                                let site_data = {
                                    site_id: site_id,
                                    site_name: site_name,
                                }
                                sites.push(site_id);
                                if (security === '0' || security === 0 || site_id === user_site){
                                    sites_data.push(site_data);
				    //console.log("Pushed site")
                                }
                            }
                        })
                        .catch(err => console.error(err));
                    })
                    .catch(err => console.error(err));
                }
            }
            else {
                alert("No calibration records found");
                return;
            }
        })
        .then(() => {
            if (calibrated_zones) {
                // get calibration analytics for zones
                this.getZoneCalibrationAnalytics(calibrated_zones) 
                this.setState({
                    calibrated_zones: calibrated_zones,
                    calibrated_zones_data: calibrated_zones_data,
                    sites: sites,
                    sites_data: sites_data,
                })  
            }
        })
        .catch(err => console.error(err));
    }

    getZoneCalibrationAnalytics = (inputZones) => {
        // inputZones is an array of unique zones from calibration records
        let zone_array = [];
        let that = this;
        let zones = inputZones;
        let bssidData = [];        
        
        // get analytics for each zone in calibration records
        zones.forEach((zone_id) => {
            // lookup zone to get zone name
            fetch(`${database}/zones/find?token=${token}&id=${zone_id}`)
            .then(response => response.json())
            .then((zone) => {
                bssidData = [];
                // count calibration records for zone
                fetch(`${database}/calibrations/count_by_zone?token=${token}&id=${zone_id}`)
                .then(response => response.json())
                .then((recordCount) => {
                    let count = Object.values(recordCount.data[0]);
                    let zoneSizeWarning = "";
                    let zoneSkewedWarning = "";
                    // get unique bssids for zone
                    fetch(`${database}/calibrations/bssid_by_zone?token=${token}&id=${zone_id}`)
                    .then(response => response.json())
                    .then((bssids) => {
                        // get all calibration records for this zone
                        fetch(`${database}/calibrations/zone?token=${token}&id=${zone_id}`)
                        .then(response => response.json())
                        .then((records) => {
                            // cycle thru bssids
                            for (let i=0; i<bssids.data.length; i++) {
                                let count = null;
                                let min = 0;
                                let max = -100;
                                let rssiCuml = 0;
                                let rssiArray = [];
                                // cycle through calibration records
                                for (let j=0; j<records.data.length; j++) {
                                    // accumulate data if the calibration record bssid matches current bssid
                                    if (records.data[j].bssid === bssids.data[i].bssid) {
                                        count++;
                                        rssiCuml = rssiCuml + records.data[j].rssi;
                                        // used later to get median
                                        rssiArray.push(records.data[j].rssi);
                                        // get min
                                        if (records.data[j].rssi < min) {
                                            min = records.data[j].rssi;
                                        }
                                        // get max
                                        if (records.data[j].rssi > max) {
                                            max = records.data[j].rssi;
                                        }
                                    }
                                }
                                // find median
                                rssiArray.sort();
                                let medianIndex = null;
                                if(rssiArray.length % 2) {
                                    medianIndex = (rssiArray.length+1)/2
                                }
                                else {
                                    medianIndex = (rssiArray.length)/2
                                }
                                let median = null;
                                if (count === 1) {
                                    median = min;
                                } 
                                else {
                                    median = rssiArray[medianIndex];
                                }
                                let avg = Math.round(rssiCuml / count);

                                if (Math.abs(min - max) > 60) {
                                    zoneSizeWarning = "This zone may be too large.";
                                }

                                if ((min !== max)&&(count>8)) {
                                    if ((Math.abs(min - median)<=5) || (Math.abs(max - median)<=5)) {
                                        // console.log("MIN:", min, "MEDIAN:", median, "MAX:", max);
                                        // console.log("Zone", zone_id, "may not have been calibrated completely.");
                                        zoneSkewedWarning = "The area of this zone may not have been calibrated completely.";
                                    }
                                }

                                let bssid_data = {
                                    zone_id: zone_id,
                                    bssid: bssids.data[i].bssid,
                                    count: count,
                                    min: min,
                                    max: max,
                                    avg: avg,
                                    median: median,
                                }
                                bssidData.push(bssid_data);
                                this.setState({});
                            }
                        })
                        .then(() => {
                            let zoneCountWarning = null;
                            if (count[0]/bssids.data.length < 40) {
                                zoneCountWarning = "Average record count is low: "+parseInt(count[0]/bssids.data.length);
                            }
                            else {
                                zoneCountWarning = null;
                            }
                            // console.log("Writing zone data for", zone.data[0].id);
                            // console.log("Count Warning:", zoneCountWarning);
                            // console.log("Size Warning:", zoneSizeWarning);
                            // console.log("Skewed Warning:", zoneSkewedWarning);
                            // console.log(object);
                            let zoneData = {
                                zone_id: zone.data[0].id,
                                floor_id: zone.data[0].floor_id,
                                site_id: zone.data[0].site_id,
                                zone_name: zone.data[0].name, 
                                record_count: count[0],
                                bssid_count: bssids.data.length,
                                zoneCountWarning: zoneCountWarning,
                                zoneSizeWarning: zoneSizeWarning,
                                zoneSkewedWarning: zoneSkewedWarning,
                                bssid_data: bssidData,
                            }
                            zone_array.push(zoneData);
                            that.setState({});
                        })
                        .catch(err => console.error(err));
                    })
                    .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
            })
            .catch(err => console.error(err)); 
        });
        this.setState({zones:zone_array});
    }

    resetZone = () => {
        // delete calibrations for a zone
        fetch(`${database}/calibrations/delete_by_zone?token=${token}&id=${this.state.reset_zone_ID}`)
        // .then(this.getDevices)
        .catch(err => console.error(err))
        this.getCalibratedZones();
        this.closeModal(); 
    }

    filterZonesForSite = (site_id) => {
        let allZones = this.state.zones;
        let tempZones = [];
        for (let i=0; i< allZones.length; i++) {
            // console.log("SITE:", allZones[i].site_id);
            if (allZones[i].site_id === parseInt(site_id, 10)) {
                tempZones.push(allZones[i]);
            }
        }
        this.setState({
            selected_site: parseInt(site_id, 10),
            selected_zones: tempZones,
        })

    }

    findMobileHotspots = (site_id) => {
        site_id = parseInt(site_id, 10);
        let allZones = this.state.calibrated_zones;
        let site_unique_bssids = [];
        let site_bssid_data = [];
        let bssid_blacklist = [];
        let promise_array1 = [];
        let promise_array2 = [];
        let promise_array3 = [];
        let promise_array4 = [];
        // fill an array of unique BSSIDs for site
        for (let i=0; i<allZones.length; i++) {
            promise_array1.push(fetch(`${database}/zones/find?token=${token}&id=${allZones[i]}`)
            .then(response => response.json())
            .then((zone) => {
                let zone_bssids = [];
                let zoneData = zone.data[0];
                if (zoneData.site_id === site_id) {
                    // console.log("FILTERED ZONE::", zoneData.id);
                    promise_array2.push(fetch(`${database}/calibrations/bssid_by_zone?token=${token}&id=${zoneData.id}`)
                    .then(response => response.json())
                    .then((bssids) => {
                        // console.log("BSSIDS::", bssids);

                        // get all calibration records for this zone
                        promise_array3.push(fetch(`${database}/calibrations/zone?token=${token}&id=${zoneData.id}`)
                        .then(response => response.json())
                        .then((records) => {
                            // cycle 3thru bssids
                            for (let i=0; i<bssids.data.length; i++) {
                                let count = null;
                                let rssiCuml = 0;
                                let rssiArray = [];
                                // cycle through calibration records
                                for (let j=0; j<records.data.length; j++) {
                                    // accumulate data if the calibration record bssid matches current bssid
                                    if (records.data[j].bssid === bssids.data[i].bssid) {
                                        count++;
                                        rssiCuml = rssiCuml + records.data[j].rssi;
                                        // used later to get median
                                        rssiArray.push(records.data[j].rssi);
                                    }
                                }
                                // find median
                                rssiArray.sort();
                                let medianIndex = null;
                                if(rssiArray.length % 2) {
                                    medianIndex = (rssiArray.length+1)/2
                                }
                                else {
                                    medianIndex = (rssiArray.length)/2
                                }
                                let median = null;
                                if (count === 1) {
                                    median = rssiArray[0];
                                } 
                                else {
                                    median = rssiArray[medianIndex];
                                }
                                // fill array with unique bssids median values
                                let bssid_data = {
                                    bssid: bssids.data[i].bssid,
                                    median: median,
                                }
                                if (zone_bssids.indexOf(bssids.data[i].bssid) === -1) {
                                    zone_bssids.push(bssid_data);
                                }   
                                // fill array with unique bssids for site                         
                                if (site_unique_bssids.indexOf(bssids.data[i].bssid) === -1) {
                                    site_unique_bssids.push(bssids.data[i].bssid);
                                }
                                this.setState({});
                            }

                        })
                        .catch(err => console.error(err)));
                        
                    })
                    .catch(err => console.error(err)));
                    
                    let zone_bssid_data = {
                        zone: zoneData.id,
                        bssids: zone_bssids,
                    }
                    site_bssid_data.push(zone_bssid_data);
                }
                
            })
            .catch(err => console.error(err)));

            promise_array4.push(this.setState({
                site_unique_bssids: site_unique_bssids,
                site_bssid_data: site_bssid_data,
            }));
        }
        
        //
        Promise.all(promise_array1)
        .then(()=>{
            Promise.all(promise_array2)
            .then(()=>{
                Promise.all(promise_array3)
                .then(()=>{
                    Promise.all(promise_array4)
                    .then(()=>{
                        if (site_unique_bssids&&site_bssid_data) {
                            // console.log("BEGIN LOOKING FOR MOBILE HOTSPOTS::");
                            // console.log("UNIQUE BSSIDs FOR SITE::", site_unique_bssids.length);
                            // console.log("ZONE BSSID DATA FOR SITE::", site_bssid_data.length);
                            // loop through each unique bssid
                            for (let i=0; i<site_unique_bssids.length; i++) {
                                // console.log("Checking BSSID (UNIQUE BSSID LOOP)::", site_unique_bssids[i]);
                                let sum_bssid_diffs = 0;
                                let diffs_count = 0;
                                // loop through each zone
                                for (let x=0; x<site_bssid_data.length; x++) {
                                    let outer_zone_bssids = site_bssid_data[x].bssids;
                                    // loop through bssids for current zone
                                    for (let y=0; y<outer_zone_bssids.length; y++) {
                                        if (outer_zone_bssids[y].bssid === site_unique_bssids[i]) {
                                            // console.log("ZONE::", site_bssid_data[x].zone, "BSSID::", outer_zone_bssids[y].bssid, "MEDIAN::", outer_zone_bssids[y].median);
                                            let outer_median = outer_zone_bssids[y].median;
                                            // loop through remaining zones
                                            for (let z=x+1; z<site_bssid_data.length; z++) {
                                                //loop through bssids for inner zone
                                                let inner_zone_bssids = site_bssid_data[z].bssids;
                                                for (let a=0; a<inner_zone_bssids.length; a++) {
                                                    // calc diff for medians of matching bssids
                                                    if (inner_zone_bssids[a].bssid === site_unique_bssids[i]){
                                                        let median_diff = Math.abs(inner_zone_bssids[a].median - outer_median);
                                                        // console.log("MEDIAN DIFF::", median_diff);
                                                        sum_bssid_diffs += median_diff;
                                                        diffs_count++;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if (diffs_count > 1) {
                                    let bssidMobileValue = sum_bssid_diffs/diffs_count;
                                    // console.log("MOBILE VALUE FOR", site_unique_bssids[i], "is::", bssidMobileValue);
                                    if (bssidMobileValue <= mobile_hotspot_threshold) {
                                        bssid_blacklist.push(site_unique_bssids[i])
                                    }
                                }
                            }
                        }
                        // console.log("BLACKLIST::", bssid_blacklist);
                        this.setState({blacklist: bssid_blacklist});
                    });
                });
            });
        });
    }

    getUncalibratedZones = (site_id) => {

        fetch(`${database}/zones/by_site/?token=${token}&site_id=${site_id}`)
        .then(response => response.json())
        .then((zones) => {
            // console.log("ZONES DATA::", zones.data);
            if (zones.data.length > 0) {
                let uncalibratedZones = [];
                for (let i = 0; i < zones.data.length; i++) {
                    // console.log("ZONE::", zones.data[i].id);
                    let zoneIsCalibrated = false;
                    for (let x = 0; x < this.state.calibrated_zones.length; x++) {
                        if (parseInt(this.state.calibrated_zones[x], 10) === parseInt(zones.data[i].id, 10)) {
                            zoneIsCalibrated = true;
                            break;
                        } 
                    }
                    if (!zoneIsCalibrated) {
                        fetch(`${database}/zones/find?token=${token}&id=${zones.data[i].id}`)
                        .then(response => response.json())
                        .then((zone) => {
                            uncalibratedZones.push(zone.data[0].name);

                        })
                        .catch(err => console.error(err));

                    }
                }
                // console.log("UNCALIBRATED ZONES::", uncalibratedZones);
                this.setState({uncalibratedZones: uncalibratedZones});
            }
        })
        .catch(err => console.error(err));

    }

    
    render() {

        return (

            <div id="diagnostics-container">

                <Header/>

                <div className="page-title">{this.state.diagnosticsHeaderLabel}</div>
                <div className='content-container'>
                <div id="diagnostics-selects">
                    <div className="diagnostics-select-label">Site</div>
                    <select 
                        name="diagnostics-site-select" 
                        className="diagnostics-site-select"
                        onChange={(event) => {
                            this.filterZonesForSite(event.target.value);
                            this.findMobileHotspots(event.target.value);
                            this.getUncalibratedZones(event.target.value);
                            this.getDevices(event.target.value);
                        }}
                        defaultValue={9999}
                    >
                    <option value={9999} disabled>Select a Site</option>
                    {this.state.sites_data && this.state.sites_data.map((value, index) => {
                        return (
                            <option value={value.site_id} key={value.site_id}>{value.site_name}</option>
                        )
                    })}
                    </select>
                </div>

                <div id="diag-devices-status">

                    {this.state.selected_site && this.state.devices &&
                    <div className="diag-devices-wrapper">
                        <div className="diag-devices-table-lbl">Devices for Site</div>

                        <table id="diag-device-table">
                            <thead>
                                <tr>
                                    <td className="diag-device-name-lbl" style={{fontWeight:"500", textAlign:"left"}}>Name</td>
                                    <td className="diag-device-status-lbl" style={{fontWeight:"500", textAlign:"left"}}>Status</td>
                                    <td className="diag-last-connect-lbl">Last Connected</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.devices.map(device => 
                                <tr className="diagnostics-device-row" key={device.id}>
                                    <td className="diag-device-name">{device.name}</td>
                                    <td className="diag-device-status">
                                        <div 
                                            className="device-led"
                                            style={{
                                                backgroundColor:device.connected===true?"#99ff66":"#d9d9d9",
                                                border:device.connected===true?"none":"1px solid #b3b3b3",
                                                boxShadow:device.connected===true?"#99ff66 0px 0px 4px":""}} 
                                        />
                                        <div className="status-text">
                                            {device.connected===true?"connected":"not connected"}
                                        </div>
                                    </td>
                                    <td className="diag-last-connect">{device.last_connected}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    }

                </div>

                <div id='diagnostics-wrapper'>
                    <div className="blacklist-wrapper">
                        {this.state.selected_site&&this.state.blacklist&&this.state.blacklist.length>0&&
                        <p style={{fontWeight:"bold"}}>These BSSIDs may be mobile hotspots.  Consider adding them to the blacklist.</p>
                        }
                        {this.state.blacklist&&this.state.blacklist.map(bssid =>
                        <p>{bssid}</p>
                        )}
                        {this.state.selected_site&&this.state.blacklist&&
                        <hr style={{marginBottom:"32px"}} />
                        }
                    </div>

                    <div className="uncalibrated-zones-wrapper">
                        {this.state.selected_site&&this.state.uncalibratedZones&&this.state.uncalibratedZones.length>0&&
                        <p style={{fontWeight:"bold"}}>These zones are not calibrated.</p>
                        }
                        {this.state.uncalibratedZones&&this.state.uncalibratedZones.map(zone =>
                        <p>{zone}</p>
                        )}
                        {this.state.selected_site&&this.state.uncalibratedZones&&
                        <hr style={{marginBottom:"32px"}} />
                        }

                    </div>

                    <div className="diag-table-title">Calibration Analytics</div>
                    {this.state.selected_site && this.state.selected_zones && this.state.selected_zones.map(zone =>
                    <table className="calibrations-table">
                        <thead>
                            <tr className="calibrations-table-hdr" key={zone.zone_id}>
                                <th className="left-justify">Zone</th>
                                <th>Record Count</th>
                                <th>BSSID Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="zone-row" key={zone.zone_id}>
                                <td className="zone-column">{zone.zone_name}</td>
                                <td className="zone-column">{zone.record_count}</td>
                                <td className="zone-column">{zone.bssid_count}</td>
                                <td>
                                    <button className="reset-button" onClick={() => this.openResetModal(zone)}>Reset</button>
                                </td>
                            </tr>

                            {zone.zoneCountWarning&&
                            <tr className="zone-quality-message">{zone.zoneCountWarning}</tr>
                            }

                            {zone.zoneSizeWarning&&
                            <tr className="zone-quality-message">{zone.zoneSizeWarning}</tr>
                            }

                            {zone.zoneSkewedWarning&&
                                <tr className="zone-quality-message">{zone.zoneSkewedWarning}</tr>
                            }

                            <Collapsible trigger="Show Data" triggerWhenOpen="Hide Data" transitionTime={200}>
                            <tr className="bssid-table-hdr" key={zone.zone_name}>
                                <th className="bssid-column">BSSID</th>
                                <th className="bssid-column">Count</th>
                                <th className="bssid-column">Avg</th>
                                <th className="bssid-column">Min</th>
                                <th className="bssid-column">Max</th>
                                <th className="bssid-column">Med</th>
                            </tr>
                            
                            {zone.bssid_data.map((bssid) => {
                                if (bssid.zone_id === zone.zone_id) {
                                    return( 
                                        <fragment>
                                        <tr className="bssid-row" key={zone.bssid} style={{marginBottom:"0"}}>
                                            <td className="bssid-column">{bssid.bssid}</td>
                                            <td className="bssid-column">{bssid.count}</td>
                                            <td className="bssid-column">{bssid.avg}</td>
                                            <td className="bssid-column">{bssid.min}</td>
                                            <td className="bssid-column">{bssid.max}</td>
                                            <td className="bssid-column">{bssid.median}</td>                                    
                                        </tr>
                                        {(Math.abs(bssid.min - bssid.median)<=5) && !(Math.abs(bssid.max - bssid.median)<=5) && (bssid.count > 8) &&
                                        <tr className="bssid-quality-message">RSSI data for this BSSID is skewed toward the min</tr>
                                        }
                                        {(Math.abs(bssid.max - bssid.median)<=5) && !(Math.abs(bssid.min - bssid.median)<=5) && (bssid.count > 8) &&
                                        <tr className="bssid-quality-message">RSSI data for this BSSID is skewed toward the max</tr>
                                        }
                                        </fragment>

                                    )
                                }

                            })}
                            </Collapsible>
                            <hr/>
                        </tbody>
                    </table>
                    )}

                </div>

                <div className="modals-wrapper">

                    <Modal
                        id="add-device-modal"
                        isOpen={this.state.resetModalIsOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="Reset Zone"
                        style={customStyles}
                    >
                        <div id="modal-content">
                            <div className="modal-header"><h1>Reset Zone Calibration</h1></div>

                            <p className="reset-message">Are you sure you want to reset the calibration data for zone: {this.state.reset_zone_name}?</p>
                            <p className="reset-message">Calibration data will be permanently deleted.</p>

                            <div className="cs-btn-group">
                                <button className="cs-btn cancel-btn" onClick={this.closeModal}>Cancel</button>
                                <button className="cs-btn submit-btn" onClick={this.resetZone}>Reset</button>
                            </div>
                        </div>
                    </Modal>

                </div>
                </div>
                <Footer/>

            </div>

        );
    }
}

export default Diagnostics;
