import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import './Preferences.css'; // Ensure you retain your CSS for consistent styling

const Preferences = () => {
  const [units, setUnits] = useState('imperial');
  const [dateFormat, setDateFormat] = useState('MM/dd/yyyy');

  useEffect(() => {
    const savedUnits = localStorage.getItem('units');
    const savedDateFormat = localStorage.getItem('dateFormat');
    if (savedUnits) {
      setUnits(savedUnits);
    }
    if (savedDateFormat) {
      setDateFormat(savedDateFormat);
    }
  }, []);

  const handleUnitsChange = (event) => {
    setUnits(event.target.value);
  };

  const handleDateFormatChange = (event) => {
    setDateFormat(event.target.value);
  };

  const handleSave = () => {
    localStorage.setItem('units', units);
    localStorage.setItem('dateFormat', dateFormat);
    alert('Preferences saved');
  };

  const theme = createTheme();

  return (
    <MuiThemeProvider theme={theme}>
      <div id="preferences-wrapper">
        <Header />

        <div className="page-title">Preferences</div>

        <Paper style={{ padding: '2rem', backgroundColor: theme.palette.background.paper, minHeight: '100vh' }}>
          <Typography variant="h4">Preferences</Typography>
          <FormControl fullWidth variant="outlined" style={{ margin: '1rem 0' }}>
            <InputLabel>Units</InputLabel>
            <Select value={units} onChange={handleUnitsChange} label="Units">
              <MenuItem value="imperial">Imperial</MenuItem>
              <MenuItem value="metric">Metric</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl fullWidth variant="outlined" style={{ margin: '1rem 0' }}>
            <InputLabel>Date Format</InputLabel>
            <Select value={dateFormat} onChange={handleDateFormatChange} label="Date Format">
              <MenuItem value="MM/dd/yyyy">MM/dd/yyyy</MenuItem>
              <MenuItem value="dd/MM/yyyy">dd/MM/yyyy</MenuItem>
              <MenuItem value="yyyy/MM/dd">yyyy/MM/dd</MenuItem>
            </Select>
          </FormControl> */}
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Paper>

        <Footer />
      </div>
    </MuiThemeProvider>
  );
};

export default Preferences;
